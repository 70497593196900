import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import QrReader from 'react-qr-reader';
import img_elImage from './images/HiceUnFavorScreen_elImage_773268.png';
import {createClient} from '@supabase/supabase-js';
import btn_icon_back_obtenerqrdelperfil from './images/btn_icon_back_obtenerqrdelperfil.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class ObtenerQRDelPerfilScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  valueChanged_elQRReader2 = (data) => {
    this.setState({qRReader2: data});
    
    console.log('after action goodness', this.context.appActions.dataSlots['ds_db_qr_scan'])
    if(!this.context.appActions.dataSlots['ds_db_qr_scan'].match(/^http/))
      return;
    if(this.context.appActions.dataSlots['ds_db_qr_scan'].match(/^http(s)*:\/\/(dev)*\.*xfavor\.org(:3000)\/perfil*(.){0,500}/)){
      let human_id = this.context.appActions.dataSlots['ds_db_qr_scan'].split("/").at(-1)
      this.context.appActions.updateDataSlot('ds_db_qr_scan', human_id);
    
      let contactsSheet = this.context.appActions.getDataSheet('contacts')
      let stopExecution = false;
      contactsSheet.items.every((contact)=>{
      	if(contact.human_id == human_id){
          this.context.appActions.goBack()	
          stopExecution = true
          return false;
        }
        return true;
      })
      if(stopExecution) return;
      let self = this
      const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
          , this.context.appActions.dataSlots['ds_supabase_api_key']
          , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
          //, options
        );
        //user information
        (async ()=>{
          const { data: profile_human, error } = await supabase
          	.from('_humans')
            .select()
          	.eq('id', human_id)
          	.single()
          
          self.context.appActions.addToDataSheet('contacts', {
            "human_id":human_id,
            "name":profile_human.name,
            "balance":0,
            "properties":profile_human.properties
          })
          
          this.context.appActions.goBack()
        })();
      
      
      	//this.context.appActions.goToScreen('perfil', this.context.baseScreenId, { transitionId: 'fadeIn' });
    }else{
    	//this is not a valid xFavor QR
      this.context.appActions.updateDataSlot('ds_notification_text', "El código QR que ha capturado no es de xFavor.");
      this.context.appActions.updateDataSlot('ds_notification_color', "red"); 
    }  
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elQRReader2 = {
      pointerEvents: 'auto',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen ObtenerQRDelPerfilScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.obtenerqrdelperfil_text_421287}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_ObtenerQRDelPerfilScreen_elQRReader2_352437">
          <div className="elQRReader2">
            <div style={style_elQRReader2}>
              
                <QrReader onScan={(result, error) => {
                  if (!!result) {
                    if('ds_db_qr_scan'!=''){
                      this.context.appActions.updateDataSlot('ds_db_qr_scan',result);
                    }
                    if(typeof this.valueChanged_elQRReader2 != "undefined")
                      this.valueChanged_elQRReader2(result);
                  }
                }} onError={(error)=>{console.log(error)}} style={{ width: '100%' }}
                constraints={{facingMode:'environment'}}
                />
            </div>
          </div>
          
          </div>
          <div className="elImage">
            <div style={style_elImage} />
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen32_navbar_657830}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_obtenerqrdelperfil} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
          </div>
        </div>
      </div>
    )
  }
  
}
