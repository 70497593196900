import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import {createClient} from '@supabase/supabase-js';
import cogoToast from 'cogo-toast';
import btn_icon_back_solicitarunfavor from './images/btn_icon_back_solicitarunfavor.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Input from 'muicss/lib/react/input';
import Textarea from 'muicss/lib/react/textarea';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class SolicitarUnFavorScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    //console.log('solicitar favor props', this.props);
    let dropdown = this.context.appActions.getDataSheet('tempDropdownData');
    dropdown.loadFromJson([])
    try{
    	this.props.dataSheetRow.properties.skills.forEach((skill)=>{
        	dropdown.addItem({"label":skill.name,"value":skill.name})
        })
      this.setState({visualStateIndexOverride: 1});
    }catch(e){console.log(e.message)}
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  getValue_elPicker = () => {
    return this.state.picker !== undefined ? this.state.picker : '';
  }
  
  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
  }
  
  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_elField = () => {
    return this.state.field || '';
  }
  
  textAreaChanged_elTextarea = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_elTextarea = () => {
    return this.state.textarea || '';
  }
  
  onClick_elButton = async () => {
    // Write your script here
    //Send request to supabase
    //console.log(this)
    
    try{
      const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
          , this.context.appActions.dataSlots['ds_supabase_api_key']
          , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
          //, options
        );
      const { data, error } = await supabase
      .from('_favor_requests')
      .insert([
        { 
          skill: this.getValue_elPicker(), actual_hours: this.getValue_elField(),
          from_human: this.context.appActions.dataSlots['ds_current_human_id'], 
          to_human: this.context.dataSheetRow.id, 
          message: this.getValue_elTextarea()||null
        },
      ])
      if(error) 
        throw error
      const hide = cogoToast.success('Tu solicitud ha sido enviado con éxito. La solicitud aparecerá públicamente en su perfil mientras todavía te deba horas.', {
        onClick: () => {
          hide();
        },hideAfter:15
        
    });
    }catch(e){
      console.log('error',e.message)
      cogoToast.error(e.message);
    }
  
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_picker = this.getValue_elPicker();
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.context.appActions.getDataSheet('tempDropdownData');
    const valueColumnName_picker = 'value';
    const labelColumnName_picker = 'label';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elField = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elText5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elTextarea = {
      display: 'block',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    const style_elCongotoast = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen SolicitarUnFavorScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.solicitarunfavor_text_117898}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.solicitarunfavor_text3_60153}</div>
            </div>
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[labelColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{this.context.locStrings.solicitarunfavor_text4_206097}</div>
            </div>
          </div>
          
          <div className="elField">
            <Input className="baseFont" style={style_elField} type="text" placeholder={this.context.locStrings.solicitarunfavor_field_767789} onChange={this.textInputChanged_elField} value={this.getValue_elField()}  />
          </div>
          
          <div className="elText5">
            <div className="baseFont" style={style_elText5}>
              <div>{this.context.locStrings.solicitarunfavor_text5_962540}</div>
            </div>
          </div>
          
          <div className="elTextarea">
            <Textarea className="baseFont" style={style_elTextarea}  placeholder={this.context.locStrings.solicitarunfavor_textarea_893734} onChange={this.textAreaChanged_elTextarea} value={this.getValue_elTextarea()}  />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.solicitarunfavor_button_400823}
            </Button>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen27_navbar_73020}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_solicitarunfavor} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
            <div className="elCongotoast" style={style_elCongotoast} />
          </div>
        </div>
      </div>
    )
  }
  
}
