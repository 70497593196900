import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import {createClient} from '@supabase/supabase-js';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';

export default class FormularioDeHabilidades extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  textInputChanged_elHabilidad = (event) => {
    this.setState({habilidad: event.target.value});
  }
  
  getValue_elHabilidad = () => {
    return this.state.habilidad || '';
  }
  
  textInputChanged_elEstudios = (event) => {
    this.setState({estudios: event.target.value});
  }
  
  getValue_elEstudios = () => {
    return this.state.estudios || '';
  }
  
  
  getValue_elTiempo_de_estudios = () => {
    let transformPropValue_tiempo_de_estudios = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      input = 12;
      return input;
    }
    
    return this.state.tiempo_de_estudios !== undefined ? this.state.tiempo_de_estudios : (transformPropValue_tiempo_de_estudios((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_activeLang'] : '')));
  }
  
  pickerValueChanged_elTiempo_de_estudios = (event) => {
    this.setState({tiempo_de_estudios: event.target.value});
  }
  
  textInputChanged_elExperiencia = (event) => {
    this.setState({experiencia: event.target.value});
  }
  
  getValue_elExperiencia = () => {
    return this.state.experiencia || '';
  }
  
  
  getValue_elTiempo_de_experiencia = () => {
    let transformPropValue_tiempo_de_experiencia = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      input = 12;
      return input;
    }
    
    return this.state.tiempo_de_experiencia !== undefined ? this.state.tiempo_de_experiencia : (transformPropValue_tiempo_de_experiencia((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_activeLang'] : '')));
  }
  
  pickerValueChanged_elTiempo_de_experiencia = (event) => {
    this.setState({tiempo_de_experiencia: event.target.value});
  }
  
  onClick_elButton = async () => {
    await this.sendData_elButton_to_skills();
  
    // Write your script he
    this.setState({habilidad: ""});
    this.setState({estudios: ""});
    this.setState({experiencia: ""});
    this.setState({tiempo_de_estudios: "12"});
    this.setState({tiempo_de_experiencia: "12"});
    
    
    this.context.appActions.updateDataSlot('ds_displayAddForm', 0);
    
    //save to supabase humans object
    let currentHuman = JSON.parse(this.context.appActions.dataSlots['ds_current_human'])
    let skillsDataSheet = this.context.appActions.getDataSheet('skills')
    console.log('currentHuman', currentHuman);
    try{
      currentHuman.properties.skills = skillsDataSheet.items;
    }catch(e){
      currentHuman.properties = {skills:skillsDataSheet.items}
    }
    
     const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
                                   , this.context.appActions.dataSlots['ds_supabase_api_key']
                                   , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
                                   //, options
                                  );
    (async ()=>{
      const { data, error } = await supabase
      .from('_humans')
      .update([{properties:currentHuman.properties}])
      .eq('id', this.context.appActions.dataSlots['ds_current_human_id'])
    })();
    
    //save to humans slot
    this.context.appActions.updateDataSlot('ds_current_human', JSON.stringify(currentHuman));
    
    //update view vars slot
    let viewVars = JSON.parse(this.context.appActions.dataSlots['ds_view_vars_obj'])
    viewVars.mySkillsCount++;
    this.context.appActions.updateDataSlot('ds_view_vars_obj', JSON.stringify(viewVars));
  
  }
  
  
  sendData_elButton_to_skills = () => {
    const dataSheet = this.context.appActions.getDataSheet('skills');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      name: this.getValue_elHabilidad(),
      skillExperienceMonths: this.getValue_elExperiencia(),
      skillEducationMonths: this.getValue_elEstudios(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      input.skillExperienceMonths = input.skillExperienceMonths * this.getValue_elTiempo_de_experiencia()
      input.skillEducationMonths = input.skillEducationMonths * this.getValue_elTiempo_de_estudios()
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.context.appActions.updateInDataSheet('skills', row);
    } else {
      return this.context.appActions.addToDataSheet('skills', row);
    }
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f4f4f4',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_elHabilidad = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elEstudios = {
      display: 'block',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    let selection_tiempo_de_estudios = this.getValue_elTiempo_de_estudios();
    const items_tiempo_de_estudios = [
      {"key": 1, "label": "Años", "value": "12"},
      {"key": 2, "label": "Meses", "value": "1"},
    ];
    
    const style_elTiempo_de_estudios = {
      pointerEvents: 'auto',
     };
    
    const style_elExperiencia = {
      display: 'block',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    let selection_tiempo_de_experiencia = this.getValue_elTiempo_de_experiencia();
    const items_tiempo_de_experiencia = [
      {"key": 1, "label": "Años", "value": "12"},
      {"key": 2, "label": "Meses", "value": "1"},
    ];
    
    const style_elTiempo_de_experiencia = {
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="FormularioDeHabilidades">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elHabilidad">
            <Input className="baseFont" style={style_elHabilidad} type="text" placeholder={this.context.locStrings.comp1_field_679395} onChange={this.textInputChanged_elHabilidad} value={this.getValue_elHabilidad()}  />
          </div>
          
          <div className="elEstudios">
            <Input className="baseFont" style={style_elEstudios} type="number" label={this.context.locStrings.comp1_field2_982235} floatingLabel={true} onChange={this.textInputChanged_elEstudios} value={this.getValue_elEstudios()}  />
          </div>
          
          <div className="elTiempo_de_estudios">
            <Select className="baseFont" style={style_elTiempo_de_estudios}  onChange={this.pickerValueChanged_elTiempo_de_estudios} value={selection_tiempo_de_estudios} >
              {items_tiempo_de_estudios.every(item => {
                return item.value !== selection_tiempo_de_estudios;
              }) ? <Option value=''/> : null}
              {items_tiempo_de_estudios.map(item => {
                return <Option key={item.key} value={item.value} label={item.label} />
              })}
            </Select>
          </div>
          
          <div className="elExperiencia">
            <Input className="baseFont" style={style_elExperiencia} type="number" label={this.context.locStrings.comp1_fieldcopy_476783} floatingLabel={true} onChange={this.textInputChanged_elExperiencia} value={this.getValue_elExperiencia()}  />
          </div>
          
          <div className="elTiempo_de_experiencia">
            <Select className="baseFont" style={style_elTiempo_de_experiencia}  onChange={this.pickerValueChanged_elTiempo_de_experiencia} value={selection_tiempo_de_experiencia} >
              {items_tiempo_de_experiencia.every(item => {
                return item.value !== selection_tiempo_de_experiencia;
              }) ? <Option value=''/> : null}
              {items_tiempo_de_experiencia.map(item => {
                return <Option key={item.key} value={item.value} label={item.label} />
              })}
            </Select>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.comp1_button_104053}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="elSupabaseJS" style={style_elSupabaseJS} />
        </div>
      </div>
    )
  }
  
}
