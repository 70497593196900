import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import {createClient} from '@supabase/supabase-js';
import FavorComponent from './FavorComponent';
import btn_icon_back_favor from './images/btn_icon_back_favor.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class FavorScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    this.context.appActions.updateDataSlot('ds_display_data', false);
    console.log(this.context.appActions.dataSlots['ds_view_param_1']);
    //this.context.appActions.setState({loading: true});
    const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
      , this.context.appActions.dataSlots['ds_supabase_api_key']
      , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
      //, options
    );
    (async ()=>{
      const { data, error } = await supabase
      	.from('_favors')
        .select(`statement,
			given_by:_humans!_favors_given_by_fkey(name),
 			received_by:_humans!_favors_received_by_fkey(name)
		`)
      	.eq('id', this.context.appActions.dataSlots['ds_view_param_1'])
      	.single()
      this.context.appActions.updateDataSlot('ds_display_data', data);
	  //this.context.appActions.setState({loading: false});
      console.log(data)
    })();
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen FavorScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" >
              {this.context.locStrings.favor_button_922590}
            </Button>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen12_navbar_755332}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_favor} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
            <div className="hasNestedComps elComp">
              <FavorComponent ref={(el)=> this._elComp = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
