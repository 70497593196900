import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import packageJson from '../package.json';
import FavoresRealizados from './FavoresRealizados';
import FavoresRecibidos from './FavoresRecibidos';
import Comp5 from './Comp5';
import HabilidadesALosQueTengoAcceso from './HabilidadesALosQueTengoAcceso';
import MisHabilidadesComp from './MisHabilidadesComp';
import btn_icon_969545 from './images/btn_icon_969545.png';
import btn_icon_957953 from './images/btn_icon_957953.png';
import img_elImage from './images/InicioScreen_elImage_727422.png';
import {createClient} from '@supabase/supabase-js';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class InicioScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    let totalFavorsOwedToMe = 0
    let totalPeopleOweToMe = 0
    let totalPeopleOwedByMe = 0
    let totalFavorsOwedByMe = 0
    let skillsAvailableToMe = []
    let displayData = this.context.displayData
    let totalPeopleIOwe = 0
    let favorsOwedToMe = this.context.appActions.getDataSheet('favorsOwedToMe')
    let favorsOwedByMe = this.context.appActions.getDataSheet('favorsOwedByMe')
	let favorRequests = this.context.appActions.getDataSheet('favorRequests')
    let hasFavorsBeenUpdated = false;
    let self = this;
    let now = Date.now()
    let maxStaleTime = 3600000
    let contactsSheet = this.context.appActions.getDataSheet('contacts')
    this.manageVersion()
    if(contactsSheet.items.length == 1 && contactsSheet.items[0].human_id == ''){
    	contactsSheet.loadFromJson([])
    }
    //console.log("time",this.context.appActions.dataSlots['ds_last_update_timestamp'], now-maxStaleTime);
    if(!favorsOwedToMe.items.length || this.context.appActions.dataSlots['ds_last_update_timestamp'] < now-maxStaleTime){
    	//data hasn't been initialized	
      //delete blank item
      favorsOwedToMe.items.forEach((item, row)=>{
      	self.context.appActions.removeFromDataSheet('favorsOwedToMe', row+1)
      })
      //pull data from server
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("apikey", self.context.appActions.dataSlots['ds_supabase_api_key']);
      myHeaders.append("Authorization", "Bearer "+self.context.appActions.dataSlots['ds_jwt']);

      var raw = JSON.stringify({
        "human_id": self.context.appActions.dataSlots['ds_current_human_id']
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
        , this.context.appActions.dataSlots['ds_supabase_api_key']
        , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
        //, options
        );
      
      fetch(this.context.appActions.dataSlots['ds_supabase_api_url']+"/rest/v1/rpc/favors_owed_to", requestOptions)
        .then(response => response.text())
        .then(items => {
        	hasFavorsBeenUpdated = true;
        	items = JSON.parse(items);
      		items.forEach((item)=>{
              	if(item.from_human == self.context.appActions.dataSlots['ds_current_human_id']){
	              //total hours are only of those who you have given a favor to directly  
                  totalFavorsOwedToMe = item.hours + (totalFavorsOwedToMe)
                }
                totalPeopleOweToMe++;
            	self.context.appActions.addToDataSheet('favorsOwedToMe', item)
              	self.context.appActions.addToDataSheet('contacts', {
                	"human_id":item.to_human,
                  	"name":item.name,
                  	"balance":item.hours,
                  	"properties":item.properties
                })
              
              	try{
                	item.properties.skills.forEach((skill)=>{
                    	skillsAvailableToMe.push(skill.name);
                    })
                }catch(e){}
            })
            self.context.appActions.updateDataSlot('ds_total_owed_to_me', totalFavorsOwedToMe);	
            self.context.appActions.updateDataSlot('ds_total_people_owe_me', totalPeopleOweToMe);
            self.context.appActions.updateDataSlot('ds_last_update_timestamp', now);
            //console.log(self.context.appActions.getDataSheet('favorsOwedToMe'))
            console.log("slot supa",self.context.appActions.dataSlots['ds_total_people_owe_me'])
        	console.log("local supa", totalPeopleOweToMe)
            console.log('Contacts',this.context.appActions.getDataSheet('contacts'))
        	this.context.appActions.updateDataSlot('ds_skills_available_to_me', skillsAvailableToMe);

      	})
        .catch(error => console.log('error', error));
      
      fetch(this.context.appActions.dataSlots['ds_supabase_api_url']+"/rest/v1/rpc/favors_owed_by", requestOptions)
        .then(response => response.text())
        .then(items => {
        	hasFavorsBeenUpdated = true;
        	items = JSON.parse(items);
      		items.forEach((item)=>{
              	totalFavorsOwedByMe = (item.hours*-1) + (totalFavorsOwedByMe)
                totalPeopleOwedByMe++;
            	self.context.appActions.addToDataSheet('favorsOwedByMe', item)
              	self.context.appActions.addToDataSheet('contacts', {
                	"human_id":item.to_human,
                  	"name":item.name,
                  	"balance":item.hours
                })
            })
            //console.log(this.context.appActions.getDataSheet('favorsOwedByMe'))
            self.context.appActions.updateDataSlot('ds_total_owed_by_me', totalFavorsOwedByMe);	
            self.context.appActions.updateDataSlot('ds_total_people_by_me', totalPeopleOwedByMe);
            self.context.appActions.updateDataSlot('ds_last_update_timestamp', now);
      	})
        .catch(error => console.log('error', error));
      
      requestOptions["body"] = undefined;
      fetch(this.context.appActions.dataSlots['ds_supabase_api_url']+"/rest/v1/rpc/current_human", requestOptions)
        .then(response => response.text())
        .then(async human => {
        	//console.log('human info',human)
        	await self.context.appActions.updateDataSlot('ds_current_human', human);
        	this.saveMySkillsToSheet();
      	})
        .catch(error => console.log('error', error));
      try{
        (async () => {
          const { data, error } = await supabase
            .from('_balances')
            .select(`hours,
                to_human:_humans!_balances_to_human_fkey(id,name,properties)
            `)
            .eq('from_human', this.context.appActions.dataSlots['ds_current_human_id'])
          if(!error && data.length){
            console.log('balances from db',data)
            //clear dataSheet
            let balancesPositiveSheet = this.context.appActions.getDataSheet('balancesPositive')
            await balancesPositiveSheet.loadFromJson([])
            let balancesNegativeSheet = this.context.appActions.getDataSheet('balancesNegative')
            await balancesNegativeSheet.loadFromJson([])
            
            data.forEach((balance,key)=>{
              if(balance.hours > 0){
				balancesPositiveSheet.addItem({
                  "hours":balance.hours,
                  "id":balance.to_human.id,
                  "name":balance.to_human.name,
                  "properties":balance.to_human.properties
                })
              }else{
                balancesNegativeSheet.addItem({
                  "hours":balance.hours*-1,//turn a negative into a positive ;)
                  "id":balance.to_human.id,
                  "name":balance.to_human.name,
                  "properties":balance.to_human.properties
                })
              }
                //data[key] = {item:data[key]}
            })
		  }
          //await displayDataSheet.loadFromJson(data)
        })();
      }catch(e){}
      //favor requests
      (async ()=>{
        let { data: favor_requests, error } = await supabase
          .from('_favor_requests')
          .select('*,from_human:_humans!_favor_requests_from_human_fkey(id,name)')
          .eq('to_human', this.context.appActions.dataSlots['ds_current_human_id'])
          .eq('active', true)
        //this.context.appActions.setState({loading: false});
        if(Array.isArray(favor_requests)){
          let preparedRequests = []	
          favor_requests.forEach((request)=>{
          	preparedRequests.push({id:request.id, data:request})
          })
          favorRequests.loadFromJson(preparedRequests);
          //console.log(preparedRequests)
          this.setState({visualStateIndexOverride: 1});

          //todo:lets move everything that changes state like updating sheets to the runWhenAllDataLoaded function
          //displayData.favorRequests = favor_requests;
          //runWhenAllDataLoaded();
        }
        //console.log('favor requests',favor_requests)
      })();
    }else{
      favorsOwedToMe.items.forEach(item=>{
        if(item.from_human == self.context.appActions.dataSlots['ds_current_human_id']){
      		totalFavorsOwedToMe = item.hours + (totalFavorsOwedToMe)
        }
        totalPeopleOweToMe++
      })
      favorsOwedByMe.items.forEach(item=>{
      	totalFavorsOwedByMe = (item.hours*-1) + (totalFavorsOwedByMe)
        totalPeopleOwedByMe++
      })
      
      //self.context.appActions.updateDataSlot('ds_total_owed_to_me', totalFavorsOwedToMe);
      //self.context.appActions.updateDataSlot('ds_total_people_owe_me', totalPeopleOweToMe);
      console.log("slot loaded",this.context.appActions.dataSlots['ds_total_people_owe_me'])
      console.log("local loaded", totalPeopleOweToMe)
      //console.log(self.context.appActions.getDataSheet('contacts'))
      //this.saveMySkillsToSheet()
    }
    
    //console.log(favorsOwedToMe);
    /*
    this.context.appActions.getDataSheet(sheetId)
    this.context.appActions.addToDataSheet(sheetId, newRow)
    this.context.appActions.updateInDataSheet(sheetId, row)
    this.context.appActions.removeFromDataSheet(sheetId, row)
    */
  }

  async saveMySkillsToSheet(){
    //console.log('saveSkills',JSON.parse(this.context.appActions.dataSlots['ds_current_human']));
    try{
      let currentHuman = JSON.parse(this.context.appActions.dataSlots['ds_current_human'])
      let skillsDataSheet = this.context.appActions.getDataSheet('skills')
      try{
      	await skillsDataSheet.loadFromJson(currentHuman.properties.skills||{})
      }catch(e){
      	await skillsDataSheet.loadFromJson([]);
      }
      let viewVars = this.context.appActions.dataSlots['ds_view_vars_obj']
      if(typeof viewVars != "object") viewVars = {};
      if(typeof viewVars.mySkillsCount == "undefined"){
        let mySkillsCount = 0
        try{
          currentHuman.properties.skills.forEach((skill)=>{
			mySkillsCount++;
          })
        }catch(e){}
        viewVars.mySkillsCount = mySkillsCount;
        this.context.appActions.updateDataSlot('ds_view_vars_obj', JSON.stringify(viewVars));

      }
      
      //set page title to current user
      this.context.locStrings.start_navbar_823812 = currentHuman.name
      
      

    }catch(e){ //console.log('saveSkillsError',e)
      let viewVars = this.context.appActions.dataSlots['ds_view_vars_obj']
        if(typeof viewVars != "object") viewVars = {};
    	viewVars.mySkillsCount = 0;
        this.context.appActions.updateDataSlot('ds_view_vars_obj', JSON.stringify(viewVars));
    }
  }
  runWhenAllDataLoaded(){
    let displayData = this.context.displayData
    if(typeof displayData == "object" && Object.keys(displayData).length < 5) return false;
  }

  manageVersion(){
    let self = this;
    const currentVersion = packageJson.version;
    if(currentVersion == "0.0.1") return false; //don't manage version for the local test site
  	fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;

        const shouldForceRefresh = self.semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          console.log('Clearing cache and hard reloading...')
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
          // delete browser cache and hard reload
          window.location.reload(true);
          //this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          //this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

 semverGreaterThan(versionA, versionB){
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elText = async () => {
    // Go to screen 'A Quienes Debo'
    this.context.appActions.goToScreen('AQuienesDebo', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elComp = async () => {
    // Go to screen 'Quienes Me Deben'
    this.context.appActions.goToScreen('quienesMeDeben', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elComp2 = async () => {
    // Go to screen 'A Quienes Debo'
    this.context.appActions.goToScreen('AQuienesDebo', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elFavorsCard = async () => {
    // Go to screen 'Solicitudes Pendientes'
    this.context.appActions.goToScreen('solicitudesPendientes', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elComp4 = async () => {
    // Go to screen 'Habilidades Disponibles'
    this.context.appActions.goToScreen('habilidadesDisponibles', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elComp3 = async () => {
    // Go to screen 'Mis Habilidades'
    this.context.appActions.goToScreen('misHabilidades', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elFab = async () => {
    // Go to screen 'Hice Un Favor'
    this.context.appActions.goToScreen('hiceUnFavor', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elFab2 = async () => {
    // Go to screen 'Recibir un Favor'
    this.context.appActions.goToScreen('recibirUnFavor', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elImage = async () => {
    // Go to screen 'Perfil'
    const param_human_id = '';
    this.context.appActions.goToScreen('perfil'+'/'+param_human_id, this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elComp = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elComp2 = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFavorsCard = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elComp4 = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let transformValue_mySkillsCount_elComp3 = (input) => {
      // This function modifies the value for property 'mySkillsCount'.
      // There is a variable named 'input' that provides the property value.
      //
      
      try{
      	input = JSON.parse(input).mySkillsCount;
      }catch(e){}
      return input;
    
    }
    const style_elComp3 = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFab2 = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen InicioScreen" style={baseStyle}>
        <div className="background">
          <div className="baseFont elText" style={style_elText} onClick={this.onClick_elText} >
            <div>{this.context.locStrings.start_text_688207}</div>
          </div>
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="hasNestedComps elComp">
            <div style={style_elComp} onClick={this.onClick_elComp} >
              <FavoresRealizados ref={(el)=> this._elComp = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elComp2">
            <div style={style_elComp2} onClick={this.onClick_elComp2} >
              <FavoresRecibidos ref={(el)=> this._elComp2 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elFavorsCard">
            <div style={style_elFavorsCard} onClick={this.onClick_elFavorsCard} >
              <Comp5 ref={(el)=> this._elFavorsCard = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elComp4">
            <div style={style_elComp4} onClick={this.onClick_elComp4} >
              <HabilidadesALosQueTengoAcceso ref={(el)=> this._elComp4 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elComp3">
            <div style={style_elComp3} onClick={this.onClick_elComp3} >
              <MisHabilidadesComp mySkillsCount={transformValue_mySkillsCount_elComp3((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_view_vars_obj'] : ''))} {...this.props} ref={(el)=> this._elComp3 = el} />
            </div>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.start_navbar_823812}</div> </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_969545} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
            <Button className="actionFont elFab2" style={style_elFab2}  variant="fab" color="accent" onClick={this.onClick_elFab2} >
              <img src={btn_icon_957953} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
            <div className="elImage" style={style_elImage} onClick={this.onClick_elImage}  />
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
          </div>
        </div>
      </div>
    )
  }
  
}

