import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class RequestsList extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    try{
      const elListRow = document.querySelectorAll(".elList > ul > li")[this.props.dataSheetRow.key-1]
	  if(this.doesOweTo(this.props.dataSheetRow.data.from_human.id)){
        elListRow.querySelector(".elRespondButton").style.display = "block"
      	elListRow.querySelector(".elRespondButton2").style.display = "none"
      }else{
        elListRow.querySelector(".elRespondButton").style.display = "none"
        elListRow.querySelector(".elRespondButton2").style.display = "block"
      }
      
	}catch(e){console.log(e)}
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  doesOweTo(human_id){
  	const humansOwedTo = this.context.appActions.getDataSheet('balancesNegative').items
    let response = false
    humansOwedTo.every((human)=>{
    	if(human_id == human.id){
        	response = true;
          	return false;
        }
      	return true;
    })
    return response;
  }

  onClick_elFrom = async () => {
    // Go to screen 'Perfil'
    const param_human_id = this.props.dataSheetRow ? this.props.dataSheetRow.data : '';
    this.context.appActions.goToScreen('perfil'+'/'+param_human_id, this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elRespondButton = async () => {
    // Go to screen 'Hice Un Favor'
    this.context.appActions.goToScreen('hiceUnFavor', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elRespondButton2 = async () => {
    // Go to screen 'Hice Un Favor'
    this.context.appActions.goToScreen('hiceUnFavor', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let transformPropValue_from = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
        const param_human_id = input.from_human.id; 
        this.onClick_elFrom = async () => {
          // Go to screen 'Perfil'
          this.context.appActions.goToScreen('perfil'+'/'+param_human_id, this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
        }
        input = input.from_human.name
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_from = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_from(this.props.dataSheetRow ? this.props.dataSheetRow.data : ''));
    
    const style_elFrom = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_hours = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      try{
      	input = input.actual_hours + " hora" + ((input.actual_hours != 1)?"s":"")
      }catch(e){console.log(e)}
      return input;
    }
    
    const value_hours = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_hours(this.props.dataSheetRow ? this.props.dataSheetRow.data : ''));
    
    const style_elHours = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_skill = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      try{
      	input = input.skill
      }catch(e){console.log(e)}
      return input;
    }
    
    const value_skill = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_skill(this.props.dataSheetRow ? this.props.dataSheetRow.data : ''));
    
    const style_elSkill = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_date = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	let date = new Date(input.created_at).toLocaleDateString(
              this.context.appActions.dataSlots['ds_activeLang'],
              {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }
          );
        input = date
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_date = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_date(this.props.dataSheetRow ? this.props.dataSheetRow.data : ''));
    
    const style_elDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_message = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.message
      }catch(e){console.log(e)}
      return input;
    }
    
    const value_message = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_message(this.props.dataSheetRow ? this.props.dataSheetRow.data : ''));
    
    const style_elMessage = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elRespondButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRespondButton2 = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elRespondButton2_outer = {
      display: 'none',
     };
    
    return (
      <div className="RequestsList cardBg">
        <div className="layoutFlow">
          <div className="elFrom">
            <div className="headlineFont" style={style_elFrom} onClick={this.onClick_elFrom} >
              <div>{value_from}</div>
            </div>
          </div>
          
          <div className="elHours">
            <div className="headlineFont" style={style_elHours}>
              <div>{value_hours}</div>
            </div>
          </div>
          
          <div className="elSkill">
            <div className="systemFontBold" style={style_elSkill}>
              <div>{value_skill}</div>
            </div>
          </div>
          
          <div className="elDate">
            <div className="baseFont" style={style_elDate}>
              <div>{value_date}</div>
            </div>
          </div>
          
          <div className="elMessage">
            <div className="baseFont" style={style_elMessage}>
              <div>{value_message}</div>
            </div>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elRespondButton">
            <Button className="actionFont" style={style_elRespondButton}  color="accent" onClick={this.onClick_elRespondButton} >
              {this.context.locStrings.requestslist_button_256804}
            </Button>
          </div>
          
          <div className="elRespondButton2" style={style_elRespondButton2_outer}>
            <Button className="actionFont" style={style_elRespondButton2}  color="accent" onClick={this.onClick_elRespondButton2} >
              {this.context.locStrings.requestslist_respondbuttoncopy_1036845}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
