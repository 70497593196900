import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import btn_icon_88043 from './images/btn_icon_88043.png';
import {createClient} from '@supabase/supabase-js';

export default class Habilidad extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow, skillExperienceMonths

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elIconButton = async () => {
    // Show question popup
    if ( !window.confirm("Está seguro de que desea borrar esta habilidad?")) {
      return;
    }
  
    // Remove row from connected sheet
    await this.context.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
    // Write your script here
    //reduce skills count
    let viewVars = JSON.parse(this.context.appActions.dataSlots['ds_view_vars_obj'])
    viewVars.mySkillsCount--;
    this.context.appActions.updateDataSlot('ds_view_vars_obj', JSON.stringify(viewVars));
    
    //save to supabase humans object
    let currentHuman = JSON.parse(this.context.appActions.dataSlots['ds_current_human'])
    let skillsDataSheet = this.context.appActions.getDataSheet('skills')
    
    try{
      currentHuman.properties.skills = skillsDataSheet.items;
    }catch(e){
      currentHuman.properties = {skills:skillsDataSheet.items}
    }
    
     const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
                                   , this.context.appActions.dataSlots['ds_supabase_api_key']
                                   , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
                                   //, options
                                  );
    (async ()=>{
      const { data, error } = await supabase
      .from('_humans')
      .update([{properties:currentHuman.properties}])
      .eq('id', this.context.appActions.dataSlots['ds_current_human_id'])
    })();
    
    //save to humans slot
    this.context.appActions.updateDataSlot('ds_current_human', JSON.stringify(currentHuman));
    
  
  }
  
  
  render() {
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	if(input > 12){
            	input = input / 12
          	input = `${input} años de estudio`
          }else if(input > 0){
          	input = `${input} meses de estudio`
          }else{
          	input = "";
          }
      }catch(e){}
      return input;
    }
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text2(this.props.dataSheetRow ? this.props.dataSheetRow.skillEducationMonths : ''));
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_textCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	if(input > 12){
            	input = input / 12
          	input = `${input} años de experiencia`
          }else if(input > 0){
          	input = `${input} meses de experiencia`
          }else{
          	input = "";
          }
      }catch(e){}
      return input;
    }
    
    const value_textCopy = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textCopy(this.props.skillExperienceMonths));
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_88043+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '84.906%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Habilidad cardBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          <div className="elSupabaseJS" style={style_elSupabaseJS} />
        </div>
      </div>
    )
  }
  
}
