import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class HabilidadesALosQueTengoAcceso extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.length
      }catch(e){}
      return input;
    }
    
    const value_text3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text3((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_skills_available_to_me'] : '')));
    
    const style_elText3 = {
      fontSize: 43.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text4 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      let response
      try{
        input.splice(10)	
        response = input.join(', ')
        if(input.count == 10){
        	response = response + "..."
        }
      }catch(e){}
      return response||"";
    }
    
    const value_text4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text4((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_skills_available_to_me'] : '')));
    
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    return (
      <div className="HabilidadesALosQueTengoAcceso cardBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.habilidadesalosquetengoacceso_text_296157}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_HabilidadesALosQueTengoAcceso_elText3_676513">
          <div className="elText3">
            <div className="systemFontRegular" style={style_elText3}>
              <div>{value_text3}</div>
            </div>
          </div>
          
          </div>
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.context.locStrings.habilidadesalosquetengoacceso_text2_121995}</div>
            </div>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{value_text4}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
