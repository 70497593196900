import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import moment from 'moment';

export default class FavorComponent extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elTextBlock5 = async () => {
    this.context.appActions.goToScreen(
      'perfil/'+this.context.appActions.dataSlots['ds_display_data'].statement.receivedBy
      , this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elTextBlock3 = async () => {
    // Write your script here
    //window.location.href = this.context.appActions.dataSlots['ds_server_name']+"/perfil/"+this.context.appActions.dataSlots['ds_display_data'].statement.givenBy
    this.context.appActions.goToScreen(
      'perfil/'+this.context.appActions.dataSlots['ds_display_data'].statement.givenBy
      , this.context.baseScreenId, { transitionId: 'fadeIn' });
    
  
  }
  
  
  render() {
    const style_elTextBlock8 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_textBlock7 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input)
      input = input.statement.agreedTotalHours
      return input;
    }
    
    const value_textBlock7 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textBlock7((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elTextBlock7 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextBlock6 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_textBlock5 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input)
      input = input.received_by.name
      return input;
    }
    
    const value_textBlock5 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textBlock5((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elTextBlock5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextBlock4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_textBlock3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input)
      input = input.given_by.name
      return input;
    }
    
    const value_textBlock3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textBlock3((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elTextBlock3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_textBlock2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input){
      	
        let date = new Date(input.statement.dateTime).toLocaleDateString(
        this.context.appActions.dataSlots['ds_activeLang'],
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
        );
        input = date
      }
        
      return input;
    }
    
    const value_textBlock2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textBlock2((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elTextBlock2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMomentJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="FavorComponent">
        <div className="foreground">
          <div className="baseFont elTextBlock8" style={style_elTextBlock8}>
            <div>{this.context.locStrings.comp5_textblock8_440353}</div>
          </div>
          <div className="headlineFont elTextBlock7" style={style_elTextBlock7}>
            <div>{value_textBlock7}</div>
          </div>
          <div className="baseFont elTextBlock6" style={style_elTextBlock6}>
            <div>{this.context.locStrings.comp5_textblock6_373648}</div>
          </div>
          <div className="headlineFont elTextBlock5" style={style_elTextBlock5} onClick={this.onClick_elTextBlock5} >
            <div>{value_textBlock5}</div>
          </div>
          <div className="baseFont elTextBlock4" style={style_elTextBlock4}>
            <div>{this.context.locStrings.comp5_textblock4_91922}</div>
          </div>
          <div className="headlineFont elTextBlock3" style={style_elTextBlock3} onClick={this.onClick_elTextBlock3} >
            <div>{value_textBlock3}</div>
          </div>
          <div className="baseFont elTextBlock2" style={style_elTextBlock2}>
            <div>{value_textBlock2}</div>
          </div>
          <div className="baseFont elTextBlock" style={style_elTextBlock}>
            <div>{this.context.locStrings.comp5_textblock_295969}</div>
          </div>
          <div className="elMomentJS" style={style_elMomentJS} />
        </div>
      </div>
    )
  }
  
}
