import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class FavoresRealizados extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elText5 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    let transformPropValue_text2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input) {
        if(input > 9 && input < 100){
        	input = Math.round((input + Number.EPSILON) * 10) / 10;
        }else if(input >= 100){
        	input = Math.round(input + Number.EPSILON);
        }else{
        	input = Math.round((input + Number.EPSILON) * 100) / 100;
        }
      }
      return input;
    }
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text2((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_total_owed_to_me'] : '')));
    
    const style_elText2 = {
      fontSize: 43.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input) input = Math.round((input + Number.EPSILON) * 100) / 100;
      return input;
    }
    
    const value_text3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text3((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_total_people_owe_me'] : '')));
    
    const style_elText3 = {
      fontSize: 43.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="FavoresRealizados cardBg">
        <div className="layoutFlow">
          <div className="flowRow flowRow_FavoresRealizados_elText5_193989">
          <div className="elText5">
            <div className="baseFont" style={style_elText5}>
              <div>{this.context.locStrings.favoresrealizados_text5_193989}</div>
            </div>
          </div>
          
          </div>
          <div className="elText2">
            <div className="systemFontRegular" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.comp1_text_1044332}</div>
            </div>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{this.context.locStrings.favoresrealizados_text4_101120}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="systemFontRegular" style={style_elText3}>
              <div>{value_text3}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="headlineFont" style={style_elTextCopy}>
              <div>{this.context.locStrings.comp1_textcopy_797782}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
