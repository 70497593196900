import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import QrReader from 'react-qr-reader';
import btn_icon_back_recibirunfavor from './images/btn_icon_back_recibirunfavor.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class RecibirUnFavorScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  valueChanged_elQRReader2 = (data) => {
    this.setState({qRReader2: data});
    
    // Write your script here
    console.log('after action goodness', this.context.appActions.dataSlots['ds_db_qr_scan'])
    if(this.context.appActions.dataSlots['ds_db_qr_scan'].match(/^http(s)*:\/\/(dev)*\.*xfavor\.org(:3000)*(.){0,500}/)){
    	window.location.href=this.context.appActions.dataSlots['ds_db_qr_scan']
      	//this.context.appActions.goToScreen('perfil', this.context.baseScreenId, { transitionId: 'fadeIn' });
    }else{
    	//this is not a valid xFavor QR
      this.context.appActions.updateDataSlot('ds_notification_text', "El código QR que ha capturado no es de xFavor.");
      this.context.appActions.updateDataSlot('ds_notification_color', "red"); 
    }
    
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elQRReader2 = {
      pointerEvents: 'auto',
     };
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textBlock2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_notification_text'] : ''));
    
    const style_elTextBlock2 = {
      color: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_notification_color'] : ''),
      textAlign: 'left',
     };
    
    return (
      <div className="AppScreen RecibirUnFavorScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elQRReader2">
            <div style={style_elQRReader2}>
              
                <QrReader onScan={(result, error) => {
                  if (!!result) {
                    if('ds_db_qr_scan'!=''){
                      this.context.appActions.updateDataSlot('ds_db_qr_scan',result);
                    }
                    if(typeof this.valueChanged_elQRReader2 != "undefined")
                      this.valueChanged_elQRReader2(result);
                  }
                }} onError={(error)=>{console.log(error)}} style={{ width: '100%' }}
                constraints={{facingMode:'environment'}}
                />
            </div>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen4_navbar_351329}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_recibirunfavor} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="baseFont elTextBlock" style={style_elTextBlock}>
              <div>{this.context.locStrings.recibirunfavor_textblock_800328}</div>
            </div>
            <div className="baseFont elTextBlock2" style={style_elTextBlock2}>
              <div>{value_textBlock2}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
