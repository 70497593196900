import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import ScreenContext from './ScreenContext.js';
import RegistroScreen from './RegistroScreen.js';
import InicioScreen from './InicioScreen.js';
import RecibirUnFavorScreen from './RecibirUnFavorScreen.js';
import HiceUnFavorScreen from './HiceUnFavorScreen.js';
import PresentarParaFirmaScreen from './PresentarParaFirmaScreen.js';
import FirmarScreen from './FirmarScreen.js';
import PerfilScreen from './PerfilScreen.js';
import FavorScreen from './FavorScreen.js';
import AQuienesDeboScreen from './AQuienesDeboScreen.js';
import QuienesMeDebenScreen from './QuienesMeDebenScreen.js';
import FavoresPorUsuarioScreen from './FavoresPorUsuarioScreen.js';
import MisHabilidadesScreen from './MisHabilidadesScreen.js';
import HabilidadesDisponiblesScreen from './HabilidadesDisponiblesScreen.js';
import SolicitarUnFavorScreen from './SolicitarUnFavorScreen.js';
import SolicitudesPendientesScreen from './SolicitudesPendientesScreen.js';
import CompartirPerfilScreen from './CompartirPerfilScreen.js';
import ObtenerQRDelPerfilScreen from './ObtenerQRDelPerfilScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_favorsOwedToMe from './DataSheet_favorsOwedToMe.js';
import DataSheet_POC_Users from './DataSheet_POC_Users.js';
import DataSheet_favorsOwedByMe from './DataSheet_favorsOwedByMe.js';
import DataSheet_contacts from './DataSheet_contacts.js';
import DataSheet_balancesPositive from './DataSheet_balancesPositive.js';
import DataSheet_specificUserFavors from './DataSheet_specificUserFavors.js';
import DataSheet_skills from './DataSheet_skills.js';
import DataSheet_balancesNegative from './DataSheet_balancesNegative.js';
import DataSheet_tempDropdownData from './DataSheet_tempDropdownData.js';
import DataSheet_humansComments from './DataSheet_humansComments.js';
import DataSheet_favorRequests from './DataSheet_favorRequests.js';


class App extends Component {
  constructor(props) {
    super(props);
    this.isEntryScreen = true;
    this.allowedEntryScreens = ["registro","inicio","firmar","perfil"];

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['favorsOwedToMe'] = new DataSheet_favorsOwedToMe('favorsOwedToMe', this.dataSheetDidUpdate);
    this.dataSheets['POC_Users'] = new DataSheet_POC_Users('POC_Users', this.dataSheetDidUpdate);
    this.dataSheets['favorsOwedByMe'] = new DataSheet_favorsOwedByMe('favorsOwedByMe', this.dataSheetDidUpdate);
    this.dataSheets['contacts'] = new DataSheet_contacts('contacts', this.dataSheetDidUpdate);
    this.dataSheets['balancesPositive'] = new DataSheet_balancesPositive('balancesPositive', this.dataSheetDidUpdate);
    this.dataSheets['specificUserFavors'] = new DataSheet_specificUserFavors('specificUserFavors', this.dataSheetDidUpdate);
    this.dataSheets['skills'] = new DataSheet_skills('skills', this.dataSheetDidUpdate);
    this.dataSheets['balancesNegative'] = new DataSheet_balancesNegative('balancesNegative', this.dataSheetDidUpdate);
    this.dataSheets['tempDropdownData'] = new DataSheet_tempDropdownData('tempDropdownData', this.dataSheetDidUpdate);
    this.dataSheets['humansComments'] = new DataSheet_humansComments('humansComments', this.dataSheetDidUpdate);
    this.dataSheets['favorRequests'] = new DataSheet_favorRequests('favorRequests', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = localStorage.getItem('ds_activeLang') || "es";
    this.dataSlots['ds_pub_sig_key'] = localStorage.getItem('ds_pub_sig_key') || "";
    this.dataSlots['ds_current_human_id'] = localStorage.getItem('ds_current_human_id') || "";
    this.dataSlots['ds_jwt'] = localStorage.getItem('ds_jwt') || "";
    this.dataSlots['ds_priv_sig_key'] = localStorage.getItem('ds_priv_sig_key') || "";
    this.dataSlots['ds_supabase_api_key'] = localStorage.getItem('ds_supabase_api_key') || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJrbGx3Z2F0cWt6YnVzb3ZydGJ4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjY2NTc4NDUsImV4cCI6MTk4MjIzMzg0NX0.dAXJlDg1z2zkB4PKEtaIbGc_r4UG-rJ-2ucOesEeRu0";
    this.dataSlots['ds_supabase_api_url'] = localStorage.getItem('ds_supabase_api_url') || "https://rkllwgatqkzbusovrtbx.supabase.co";
    this.dataSlots['ds_total_owed_to_me'] = localStorage.getItem('ds_total_owed_to_me') || "0";
    this.dataSlots['ds_total_i_owe'] = localStorage.getItem('ds_total_i_owe') || "";
    this.dataSlots['ds_total_people_owe_me'] = localStorage.getItem('ds_total_people_owe_me') || "0";
    this.dataSlots['ds_last_update_timestamp'] = localStorage.getItem('ds_last_update_timestamp') || "1667972105000";
    this.dataSlots['ds_total_owed_by_me'] = localStorage.getItem('ds_total_owed_by_me') || "0";
    this.dataSlots['ds_total_people_by_me'] = localStorage.getItem('ds_total_people_by_me') || "0";
    this.dataSlots['ds_db_qr_scan'] = "";
    this.dataSlots['ds_qr_data_to_generate'] = "dynamic data here";
    this.dataSlots['ds_server_name'] = localStorage.getItem('ds_server_name') || "http://localhost:3000";
    this.dataSlots['ds_view_param_1'] = "";
    this.dataSlots['ds_display_data'] = "";
    this.dataSlots['ds_notification_text'] = "";
    this.dataSlots['ds_notification_color'] = "";
    this.dataSlots['ds_current_human'] = localStorage.getItem('ds_current_human') || "";
    this.dataSlots['ds_displayAddForm'] = "0";
    this.dataSlots['ds_view_vars_obj'] = localStorage.getItem('ds_view_vars_obj') || "";
    this.dataSlots['ds_skills_available_to_me'] = localStorage.getItem('ds_skills_available_to_me') || "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);

    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, baseScreenId, props) => {
    // Implemented with React Router's 'history' object.
    // 'baseScreenId' is set when inside a container such as a tab bar.
    let path = '/'+screenId;
    if (baseScreenId && baseScreenId.length > 0) {
      path = '/'+baseScreenId+path;
      props = {};
    }
    this.props.history.push(path, {...props});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // Implemented with React Router's 'history' object.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }
    if (slotId === 'ds_activeLang') {
      localStorage.setItem('ds_activeLang', value);
    }
    if (slotId === 'ds_pub_sig_key') {
      localStorage.setItem('ds_pub_sig_key', value);
    }
    if (slotId === 'ds_current_human_id') {
      localStorage.setItem('ds_current_human_id', value);
    }
    if (slotId === 'ds_jwt') {
      localStorage.setItem('ds_jwt', value);
    }
    if (slotId === 'ds_priv_sig_key') {
      localStorage.setItem('ds_priv_sig_key', value);
    }
    if (slotId === 'ds_supabase_api_key') {
      localStorage.setItem('ds_supabase_api_key', value);
    }
    if (slotId === 'ds_supabase_api_url') {
      localStorage.setItem('ds_supabase_api_url', value);
    }
    if (slotId === 'ds_total_owed_to_me') {
      localStorage.setItem('ds_total_owed_to_me', value);
    }
    if (slotId === 'ds_total_i_owe') {
      localStorage.setItem('ds_total_i_owe', value);
    }
    if (slotId === 'ds_total_people_owe_me') {
      localStorage.setItem('ds_total_people_owe_me', value);
    }
    if (slotId === 'ds_last_update_timestamp') {
      localStorage.setItem('ds_last_update_timestamp', value);
    }
    if (slotId === 'ds_total_owed_by_me') {
      localStorage.setItem('ds_total_owed_by_me', value);
    }
    if (slotId === 'ds_total_people_by_me') {
      localStorage.setItem('ds_total_people_by_me', value);
    }
    if (slotId === 'ds_server_name') {
      localStorage.setItem('ds_server_name', value);
    }
    if (slotId === 'ds_current_human') {
      localStorage.setItem('ds_current_human', value);
    }
    if (slotId === 'ds_view_vars_obj') {
      localStorage.setItem('ds_view_vars_obj', value);
    }
    if (slotId === 'ds_skills_available_to_me') {
      localStorage.setItem('ds_skills_available_to_me', value);
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "(null)"+prop.path;
      }
    }
    return prop;
  }

  render() {
    const makeScreen = (screenId, baseProps, atTop, forward, subpathId) => {
      let screenCtxProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_pub_sig_key': this.dataSlots['ds_pub_sig_key'],
        'ds_current_human_id': this.dataSlots['ds_current_human_id'],
        'ds_jwt': this.dataSlots['ds_jwt'],
        'ds_priv_sig_key': this.dataSlots['ds_priv_sig_key'],
        'ds_supabase_api_key': this.dataSlots['ds_supabase_api_key'],
        'ds_supabase_api_url': this.dataSlots['ds_supabase_api_url'],
        'ds_total_owed_to_me': this.dataSlots['ds_total_owed_to_me'],
        'ds_total_i_owe': this.dataSlots['ds_total_i_owe'],
        'ds_total_people_owe_me': this.dataSlots['ds_total_people_owe_me'],
        'ds_last_update_timestamp': this.dataSlots['ds_last_update_timestamp'],
        'ds_total_owed_by_me': this.dataSlots['ds_total_owed_by_me'],
        'ds_total_people_by_me': this.dataSlots['ds_total_people_by_me'],
        'ds_db_qr_scan': this.dataSlots['ds_db_qr_scan'],
        'ds_qr_data_to_generate': this.dataSlots['ds_qr_data_to_generate'],
        'ds_server_name': this.dataSlots['ds_server_name'],
        'ds_view_param_1': this.dataSlots['ds_view_param_1'],
        'ds_display_data': this.dataSlots['ds_display_data'],
        'ds_notification_text': this.dataSlots['ds_notification_text'],
        'ds_notification_color': this.dataSlots['ds_notification_color'],
        'ds_current_human': this.dataSlots['ds_current_human'],
        'ds_displayAddForm': this.dataSlots['ds_displayAddForm'],
        'ds_view_vars_obj': this.dataSlots['ds_view_vars_obj'],
        'ds_skills_available_to_me': this.dataSlots['ds_skills_available_to_me'],
      };
      let screen;
      if(this.isEntryScreen && this.allowedEntryScreens.indexOf(screenId) == -1)
        {screenId = "registro";}
      this.isEntryScreen = false;
      switch (screenId) {
        case 'registro':
          screen = <RegistroScreen {...baseProps} />;
          break;
        case 'inicio':
          screen = <InicioScreen {...baseProps} />;
          break;
        case 'recibirUnFavor':
          screen = <RecibirUnFavorScreen {...baseProps} />;
          break;
        case 'hiceUnFavor':
          screen = <HiceUnFavorScreen {...baseProps} />;
          break;
        case 'presentarParaFirma':
          screen = <PresentarParaFirmaScreen {...baseProps} />;
          break;
        case 'firmar':
          screen = <FirmarScreen {...baseProps} />;
          break;
        case 'perfil':
          screen = <PerfilScreen {...baseProps} />;
          break;
        case 'favor':
          screen = <FavorScreen {...baseProps} />;
          break;
        case 'AQuienesDebo':
          screen = <AQuienesDeboScreen {...baseProps} />;
          break;
        case 'quienesMeDeben':
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_QuienesMeDebenScreen = this.dataSheets['balancesPositive'].items[0];
      const screenData_QuienesMeDebenScreen = {
        ...dataSheetRow_QuienesMeDebenScreen,
        dataSheetRow: dataSheetRow_QuienesMeDebenScreen,
      }
          screen = <QuienesMeDebenScreen {...baseProps} {...screenData_QuienesMeDebenScreen} />;
          break;
        case 'favoresPorUsuario':
          screen = <FavoresPorUsuarioScreen {...baseProps} />;
          break;
        case 'misHabilidades':
          screen = <MisHabilidadesScreen {...baseProps} />;
          break;
        case 'habilidadesDisponibles':
          screen = <HabilidadesDisponiblesScreen {...baseProps} />;
          break;
        case 'solicitarUnFavor':
          screen = <SolicitarUnFavorScreen {...baseProps} />;
          break;
        case 'solicitudesPendientes':
          screen = <SolicitudesPendientesScreen {...baseProps} />;
          break;
        case 'compartirPerfil':
          screen = <CompartirPerfilScreen {...baseProps} />;
          break;
        case 'obtenerQRDelPerfil':
          screen = <ObtenerQRDelPerfilScreen {...baseProps} />;
          break;
        default:
          screen = null;
      }
      if (screen) {
        return <ScreenContext.Provider value={screenCtxProps}>{screen}</ScreenContext.Provider>;
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => {
            return (sessionStorage.getItem('loginStatus_registro') === 'active') ? <Redirect to="/registro" /> : <Redirect to="/registro" />;
          }} exact />
          <Route path="/registro" render={(props) => {
            return makeScreen('registro', props.location.state, true, true);
          }} />
          <Route path="/inicio" render={(props) => {
            return makeScreen('inicio', props.location.state, true, true);
          }} />
          <Route path="/recibirUnFavor" render={(props) => {
            return makeScreen('recibirUnFavor', props.location.state, true, true);
          }} />
          <Route path="/hiceUnFavor" render={(props) => {
            return makeScreen('hiceUnFavor', props.location.state, true, true);
          }} />
          <Route path="/presentarParaFirma" render={(props) => {
            return makeScreen('presentarParaFirma', props.location.state, true, true);
          }} />
          <Route path="/firmar/:unsigned_id" render={(props) => {
            this.updateDataSlot('ds_view_param_1', props.match.params.unsigned_id);
            return makeScreen('firmar', props.location.state, true, true);
          }} />
          <Route path="/firmar" render={(props) => {
            return makeScreen('firmar', props.location.state, true, true);
          }} />
          <Route path="/perfil/:human_id" render={(props) => {
            this.updateDataSlot('ds_view_param_1', props.match.params.human_id);
            return makeScreen('perfil', props.location.state, true, true);
          }} />
          <Route path="/perfil" render={(props) => {
            return makeScreen('perfil', props.location.state, true, true);
          }} />
          <Route path="/favor/:param0" render={(props) => {
            this.updateDataSlot('ds_view_param_1', props.match.params.param0);
            return makeScreen('favor', props.location.state, true, true);
          }} />
          <Route path="/favor" render={(props) => {
            return makeScreen('favor', props.location.state, true, true);
          }} />
          <Route path="/AQuienesDebo" render={(props) => {
            return makeScreen('AQuienesDebo', props.location.state, true, true);
          }} />
          <Route path="/quienesMeDeben" render={(props) => {
            return makeScreen('quienesMeDeben', props.location.state, true, true);
          }} />
          <Route path="/favoresPorUsuario" render={(props) => {
            return makeScreen('favoresPorUsuario', props.location.state, true, true);
          }} />
          <Route path="/misHabilidades" render={(props) => {
            return makeScreen('misHabilidades', props.location.state, true, true);
          }} />
          <Route path="/habilidadesDisponibles" render={(props) => {
            return makeScreen('habilidadesDisponibles', props.location.state, true, true);
          }} />
          <Route path="/solicitarUnFavor" render={(props) => {
            return makeScreen('solicitarUnFavor', props.location.state, true, true);
          }} />
          <Route path="/solicitudesPendientes" render={(props) => {
            return makeScreen('solicitudesPendientes', props.location.state, true, true);
          }} />
          <Route path="/compartirPerfil" render={(props) => {
            return makeScreen('compartirPerfil', props.location.state, true, true);
          }} />
          <Route path="/obtenerQRDelPerfil" render={(props) => {
            return makeScreen('obtenerQRDelPerfil', props.location.state, true, true);
          }} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
