import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import TwoColumnTableItem from './TwoColumnTableItem';

export default class Comp4 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      elList_visible: true,
    };
  }

  componentDidMount() {
    let displayData = this.context.appActions.dataSlots['ds_display_data']
    let statement = displayData.statement
    let dataSheet = this.context.appActions.getDataSheet('tempDropdownData')
    let details = []
    let date = new Date(statement.dateTime).toLocaleDateString(
        this.context.appActions.dataSlots['ds_activeLang'],
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
    );
    
    details.push(["Fecha",date]);
    details.push(["Duración del Favor", this.pluralOrSingular(statement.actualHours, "hora")])
    if(Array.isArray(statement.skills) && statement.skills.length){
    	details.push(["Habilidad", statement.skills[0].name])
      	if(statement.skills[0].skillExperienceMonths){
        	details.push(["Experiencia", this.durationText(statement.skills[0].skillExperienceMonths)])
            if(statement.skills[0].skillExperienceMonths > 120)
                statement.skills[0].skillExperienceMonths = 120
            details.push(["Adicional por Experiencia", this.pluralOrSingular(Math.round(((statement.skills[0].skillExperienceMonths * statement.actualHours)/120)*100)/100, "hora")]);

        }
      	if(statement.skills[0].skillEducationMonths){
        	details.push(["Estudios", this.durationText(statement.skills[0].skillEducationMonths)])    
            if(statement.skills[0].skillEducationMonths > 120)
              statement.skills[0].skillEducationMonths = 120
            details.push(["Adicional por Estudios", this.pluralOrSingular(Math.round(((statement.skills[0].skillEducationMonths * statement.actualHours)/120)*100)/100,"hora")]);
        
        }
      	if(statement.skills[0].skillExperienceMonths || statement.skills[0].skillEducationMonths){
        	details.push(["Total de Horas", statement.agreedTotalHours])
        }
    }
    /*if(statement.given_by_comments && statement.given_by_comments.length){
    	details.push({"Comentarios de "+given_by.name:statement.given_by_comments})
    }*/
    dataSheet.loadFromJson(details)
    this.setState({elList_visible: !this.state.elList_visible});
    
    console.log(displayData, details)
  }


  durationText(period){
    if(period > 12){
      period = period / 12
      period = this.pluralOrSingular(period, "año")
    }else if(period > 0){
      period = this.pluralOrSingular(period, "mes")
    }else{
      period = "";
    }
    return period
  }
  pluralOrSingular(period, word){
  	if(period == 1) return period +" "+ word;
    if(word == "año") return period +" "+ "años"
    if(word == "mes") return period +" "+ "meses"
    if(word == "hora") return period +" "+ "horas"
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elText5 = async () => {
    this.setState({elList_visible: !this.state.elList_visible});
  
  }
  
  
  
  render() {
    // eslint-disable-next-line
    const dataSheet_tempDropdownData = this.context.appActions.getDataSheet('tempDropdownData');
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_dynamic_received = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      //console.log(input)
      if(input){
        input = input.received_by.name
      }
      return input;
    }
    
    const value_dynamic_received = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_dynamic_received((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elDynamic_received = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_dynamic_given = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      //console.log(input)
      if(input){
        input = input.given_by.name
      }
      return input;
    }
    
    const value_dynamic_given = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_dynamic_given((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elDynamic_given = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_dynamic_hours = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      //console.log(input)
      if(input){
        input = input.statement.agreedTotalHours
      }
      return input;
    }
    
    const value_dynamic_hours = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_dynamic_hours((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elDynamic_hours = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('tempDropdownData').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const elList = this.state.elList_visible ? (
      <div className="hasNestedComps elList">
        <ul style={style_elList}>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <TwoColumnTableItem dataSheetId={'tempDropdownData'} dataSheetRow={row} ref={(el) => {if (el) this._elList_items.push(el)}} />;
            return (<li key={row.key || index}>
                {itemComp}
              </li>);
          })}
          <div className="marker" ref={(el)=> this._elList_endMarker = el} />
        </ul>
      </div>
      
     ) : null;
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="Comp4">
        <div className="layoutFlow">
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{this.context.locStrings.comp4_text4_534043}</div>
            </div>
          </div>
          
          <div className="elDynamic_received">
            <div className="baseFont" style={style_elDynamic_received}>
              <div>{value_dynamic_received}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.comp4_text3_536217}</div>
            </div>
          </div>
          
          <div className="elDynamic_given">
            <div className="baseFont" style={style_elDynamic_given}>
              <div>{value_dynamic_given}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.comp4_text_831992}</div>
            </div>
          </div>
          
          <div className="elDynamic_hours">
            <div className="systemFontBold" style={style_elDynamic_hours}>
              <div>{value_dynamic_hours}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.context.locStrings.comp4_text2_81608}</div>
            </div>
          </div>
          
          <div className="elText5">
            <div className="actionFont" style={style_elText5} onClick={this.onClick_elText5} >
              <div>{this.context.locStrings.comp4_text5_497492}</div>
            </div>
          </div>
          
          { elList }
          <div className="elTextBlock">
            <div className="baseFont" style={style_elTextBlock}>
              <div>{this.context.locStrings.comp4_textblock_745193}</div>
            </div>
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
