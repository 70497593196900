import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class ListItem1 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elButton = async () => {
    // Go to screen 'Solicitar un Favor'
    this.context.appActions.goToScreen('solicitarUnFavor', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.balance : '');
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text4 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      console.log('habilidades listado', input)
      let monthsOrYears = (date)=>{
      	if(date > 12){
            date = (date / 12) + " años"
          }else{
            date = date + " meses"
          }
        return date;
      }
      let response = ""
      try{
        	input.skills.forEach((skill)=>{
            let qualifications = []
            if(skill.skillEducationMonths) 
              qualifications.push(monthsOrYears(skill.skillEducationMonths)+" de educación")
            if(skill.skillExperienceMonths) 
              qualifications.push(monthsOrYears(skill.skillExperienceMonths)+" de experiencia")
            response = skill.name 
            if(qualifications.length) response = response + " <span style='font-size:80%'>(" + qualifications.join(", ") + ")</span>"
            response = response + "<br><br>"
          })
      }catch(e){console.log(e)}
      return response;
    }
    
    const value_text4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text4(this.props.dataSheetRow ? this.props.dataSheetRow.properties : ''));
    
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ListItem1 cardBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.listitem1_text3_403331}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div><div dangerouslySetInnerHTML={{__html: value_text4}}></div></div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.listitem1_button_134987}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
