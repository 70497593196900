import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class MisHabilidadesComp extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // mySkillsCount

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textBlock = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.mySkillsCount);
    
    const style_elTextBlock = {
      fontSize: 43.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="MisHabilidadesComp cardBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.mishabilidades_text_974197}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_MisHabilidadesComp_elTextBlock_31216">
          <div className="elTextBlock">
            <div className="systemFontRegular" style={style_elTextBlock}>
              <div>{value_textBlock}</div>
            </div>
          </div>
          
          </div>
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.context.locStrings.mishabilidades_text2_378880}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
