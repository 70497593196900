import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Comp4 from './Comp4';
import {createClient} from '@supabase/supabase-js';
import nacl from 'tweetnacl';
import scrypt from 'scrypt-async';
import btn_icon_back_firmar from './images/btn_icon_back_firmar.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class FirmarScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    this.context.appActions.updateDataSlot('ds_display_data', false);
    //this.context.appActions.setState({loading: true});
    const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
      , this.context.appActions.dataSlots['ds_supabase_api_key']
      , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
      //, options
    );
    (async ()=>{
      const { data, error } = await supabase
      	.from('_unsigned_favors')
        .select(`statement,
			given_by:_humans!_unsigned_favors_given_by_fkey(name),
 			received_by:_humans!_unsigned_favors_received_by_fkey(name)
		`)
      	.eq('id', this.context.appActions.dataSlots['ds_view_param_1'])
      	.single()
      this.context.appActions.updateDataSlot('ds_display_data', data);
	  //this.context.appActions.setState({loading: false});
      //console.log(data)
    })();
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elButton = async () => {
    // Write your script here
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.context.appActions.dataSlots['ds_jwt']);
    myHeaders.append("apikey", this.context.appActions.dataSlots['ds_supabase_api_key']);
    
    let statement = this.context.appActions.dataSlots['ds_display_data'].statement;
    let statementText = JSON.stringify(statement)
    //import private key and sign
    const privateKey = Buffer.from(this.context.appActions.dataSlots['ds_priv_sig_key'], 'hex')
    //console.log(privateKey);
    const pair = nacl.sign.keyPair.fromSecretKey(privateKey);
    const sign_result = nacl.sign.detached(new TextEncoder().encode(statementText), pair.secretKey)
    const signature = Buffer.from(sign_result).toString("hex") 
    //console.log("Signature", signature);
    var raw = JSON.stringify({
      "signature": signature,
      "statement": statement
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    const endpointUrl = "https://rkllwgatqkzbusovrtbx.functions.supabase.co/favors"
    fetch(endpointUrl, requestOptions)
      .then(response => response.text())
      .then(result => {
      if(result && result.length){
        result = JSON.parse(result);
        console.log(result);
        //window.location.href = this.context.appActions.dataSlots['ds_server_name']+"/favor/"+result.id
        this.context.appActions.goToScreen(
          'favor/'+result.id
          , this.context.baseScreenId, { transitionId: 'fadeIn' });
        //this.context.appActions.updateDataSlot('ds_jwt', jwtInfo.jwtToken);
        //this.context.appActions.goToScreen('start', this.context.baseScreenId, { transitionId: 'fadeIn' });
    
      }
    })
      .catch(error => console.log('error', error));
  
  }
  
  
  onClick_elButton2 = async () => {
    // Go to screen 'Inicio'
    this.context.appActions.goToScreen('inicio', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    let transformVisiblePropValue_comp = (input) => {
      // This function modifies the value for property 'visible'.
      // There is a variable named 'input' that provides the property value.
      //
      return (input?true:false);
    }
    const elComp = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_comp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''))) ? (
      <div className="hasNestedComps elComp">
        <div>
          <Comp4 ref={(el)=> this._elComp = el} />
        </div>
      </div>
      
     ) : null;
    let transformPropValue_button = (input) => {
      // This function modifies the value for property 'enabled'.
      // There is a variable named 'input' that provides the property value.
      // The function must return 'true' (with apostrophes) for enabled value.
      //
      return (input?true:false);;
    }
    
    const enabledValue_button = transformPropValue_button((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''));
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButton2 = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    const style_elTweetnacl = {
      pointerEvents: 'auto',
     };
    const style_elTweetnacl2 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen FirmarScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          { elComp }
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" disabled={''+enabledValue_button !== 'true'} onClick={this.onClick_elButton} >
              {this.context.locStrings.firmar_button_1021444}
            </Button>
          </div>
          
          <div className="elButton2">
            <Button className="actionFont" style={style_elButton2} onClick={this.onClick_elButton2} >
              {this.context.locStrings.firmar_button2_264888}
            </Button>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen8_navbar_945923}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_firmar} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
            <div className="elTweetnacl" style={style_elTweetnacl} />
            <div className="elTweetnacl2" style={style_elTweetnacl2} />
          </div>
        </div>
      </div>
    )
  }
  
}
