import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import QRCode from 'react-qr-code';
import btn_icon_back_compartirperfil from './images/btn_icon_back_compartirperfil.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class CompartirPerfilScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    let transformPropValue_text = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.profile_human.name
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elQrwriter = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen CompartirPerfilScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.context.locStrings.compartirperfil_text2_215215}</div>
            </div>
          </div>
          
          <div className="elQrwriter">
            <div style={style_elQrwriter}>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: "70%", width: "70%", padding: "15px" }}>
                <QRCode value={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_qr_data_to_generate'] : '')}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                size={600}
                viewBox={[0,0,600,600]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen31_navbar_225993}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_compartirperfil} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
      </div>
    )
  }
  
}
