import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elRoundimage from './images/RegistroScreen_elRoundimage_444849.png';
import {createClient} from '@supabase/supabase-js';
import nacl from 'tweetnacl';
import scrypt from 'scrypt-async';
import btn_icon_back_registro from './images/btn_icon_back_registro.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Appbar from 'muicss/lib/react/appbar';

export default class RegistroScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    this.state = {
      elPicker_visible: false,
    };
  }

  componentDidMount() {
    if(this.context.appActions.dataSlots['ds_current_human_id'] && this.context.appActions.dataSlots['ds_jwt'] ){
    	this.context.appActions.goToScreen('inicio', this.context.baseScreenId);
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elRoundimage = async () => {
    this.setState({elPicker_visible: true});
  
  }
  
  
  textInputChanged_elName = (event) => {
    this.setState({name: event.target.value});
  }
  
  getValue_elName = () => {
    return this.state.name || '';
  }
  
  onClick_elButton = async () => {
    // Write your script here
    
    function buff2hex (arrayBuffer) {
        if (typeof arrayBuffer !== 'object' || arrayBuffer === null || typeof arrayBuffer.byteLength !== 'number') {
          throw new TypeError('Expected input to be an ArrayBuffer')
        }
      
        var view = new Uint8Array(arrayBuffer)
        var result = ''
        var value
      
        for (var i = 0; i < view.length; i++) {
          value = view[i].toString(16)
          result += (value.length === 1 ? '0' + value : value)
        }
      
        return result
      }
    function hex2buff (input) {
        const view = new Uint8Array(input.length / 2)
        for (let i = 0; i < input.length; i += 2) {
          view[i / 2] = parseInt(input.substring(i, i + 2), 16)
        }
        return view.buffer
    }
    
    let name = this.state.name
    if(!name || !name.length) return;
    //generate a new key pair
    let keyPair = nacl.sign.keyPair()
    
    //save private and public keys
    this.context.appActions.updateDataSlot('ds_priv_sig_key', buff2hex(keyPair.secretKey));
    this.context.appActions.updateDataSlot('ds_pub_sig_key', buff2hex(keyPair.publicKey));
    
    //create a new user
    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+this.context.appActions.dataSlots['ds_supabase_api_key']);
      myHeaders.append("apikey", this.context.appActions.dataSlots['ds_supabase_api_key']);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Prefer", "return=representation");
      
      var raw = JSON.stringify({
        "name": name,
        "pub_sig_key": this.context.appActions.dataSlots['ds_pub_sig_key']
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
      };
      const endpointUrl = "https://rkllwgatqkzbusovrtbx.supabase.co/rest/v1/_humans"
      fetch(endpointUrl, requestOptions)
        .then(response => response.text())
        .then(async result => {
        	  result = JSON.parse(result);
              if(Array.isArray(result) && result.length){
                //fetch new user id
               	this.context.appActions.updateDataSlot('ds_current_human_id', result[0].id); 
                //log in            
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer "+this.context.appActions.dataSlots['ds_supabase_api_key']);
                myHeaders.append("apikey", this.context.appActions.dataSlots['ds_supabase_api_key']);
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                  };
                  const endpointUrl = "https://rkllwgatqkzbusovrtbx.functions.supabase.co/auth"
                  fetch(endpointUrl
                        +"?human_id="
                        +this.context.appActions.dataSlots['ds_current_human_id'], requestOptions)
                    .then(response => response.text())
                    .then(async result => {
                        if(result && result.length){
                            const userAuthInfo = JSON.parse(result);
                            //import private key and sign
                            const privateKey = Buffer.from(this.context.appActions.dataSlots['ds_priv_sig_key'], 'hex')
                            //console.log(privateKey);
                            const pair = nacl.sign.keyPair.fromSecretKey(privateKey);
                            const sign_result = nacl.sign.detached(new TextEncoder().encode(userAuthInfo.nonce), pair.secretKey)
                            const signature = Buffer.from(sign_result).toString("hex") 
                            console.log("Signature", signature);
                            var raw = JSON.stringify({
                              "nonce": userAuthInfo.nonce,
                              "sig": signature,
                              "human_id": userAuthInfo.human_id
                            });
                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: raw,
                              redirect: 'follow'
                            };
                            fetch(endpointUrl, requestOptions)
                              .then(response => response.text())
                              .then(result => {
                                    if(result && result.length){
                                        const jwtInfo = JSON.parse(result);
                                        console.log(jwtInfo);
                                        this.context.appActions.updateDataSlot('ds_jwt', jwtInfo.jwtToken);                               
    									//redirect
                                        this.context.appActions.goToScreen('inicio', this.context.baseScreenId, { transitionId: 'fadeIn' });
    
                                    }
                              })
                              .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
              }
        	
        
      	})
        .catch(error => console.log('error', error));
    }catch(e){
    	console.log(e.message)
    }
  
  }
  
  
  
  getValue_elPicker = () => {
    return this.state.picker !== undefined ? this.state.picker : '';
  }
  
  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
    
    const usersList = this.context.appActions.getDataSheet('POC_Users')
    usersList.items.forEach(item => {
        if(item.id === event.target.value){
            this.context.appActions.updateDataSlot("ds_current_human_id", item.id);
            this.context.appActions.updateDataSlot("ds_pub_sig_key", item.pub_sig_key);
            this.context.appActions.updateDataSlot("ds_priv_sig_key", item.priv_sig_key);        
        }
        //todo: fetch jwt by fetching nonce, signing it and submitting
    });
    function buff2hex (arrayBuffer) {
        if (typeof arrayBuffer !== 'object' || arrayBuffer === null || typeof arrayBuffer.byteLength !== 'number') {
          throw new TypeError('Expected input to be an ArrayBuffer')
        }
      
        var view = new Uint8Array(arrayBuffer)
        var result = ''
        var value
      
        for (var i = 0; i < view.length; i++) {
          value = view[i].toString(16)
          result += (value.length === 1 ? '0' + value : value)
        }
      
        return result
      }
    function hex2buff (input) {
        const view = new Uint8Array(input.length / 2)
        for (let i = 0; i < input.length; i += 2) {
          view[i / 2] = parseInt(input.substring(i, i + 2), 16)
        }
        return view.buffer
    }
    
    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+this.context.appActions.dataSlots['ds_supabase_api_key']);
      myHeaders.append("apikey", this.context.appActions.dataSlots['ds_supabase_api_key']);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const endpointUrl = "https://rkllwgatqkzbusovrtbx.functions.supabase.co/auth"
      fetch(endpointUrl
            +"?human_id="
            +this.context.appActions.dataSlots['ds_current_human_id'], requestOptions)
      	.then(response => response.text())
        .then(async result => {
        	if(result && result.length){
               	const userAuthInfo = JSON.parse(result);
              	//import private key and sign
                const privateKey = Buffer.from(this.context.appActions.dataSlots['ds_priv_sig_key'], 'hex')
                //console.log(privateKey);
                const pair = nacl.sign.keyPair.fromSecretKey(privateKey);
                const sign_result = nacl.sign.detached(new TextEncoder().encode(userAuthInfo.nonce), pair.secretKey)
              	const signature = Buffer.from(sign_result).toString("hex") 
              	console.log("Signature", signature);
                var raw = JSON.stringify({
                  "nonce": userAuthInfo.nonce,
                  "sig": signature,
                  "human_id": userAuthInfo.human_id
                });
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };
              	fetch(endpointUrl, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                  		if(result && result.length){
                        	const jwtInfo = JSON.parse(result);
                          	console.log(jwtInfo);
                          	this.context.appActions.updateDataSlot('ds_jwt', jwtInfo.jwtToken);
                          	this.context.appActions.goToScreen('inicio', this.context.baseScreenId, { transitionId: 'fadeIn' });
                        }
                  })
                  .catch(error => console.log('error', error));
            }
      	})
        .catch(error => console.log('error', error));
    }catch(e){
    	console.log(e.message)
    }  
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elRoundimage = {
      backgroundImage: 'url('+img_elRoundimage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elName = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_picker = this.getValue_elPicker();
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.context.appActions.getDataSheet('POC_Users');
    const valueColumnName_picker = 'id';
    const labelColumnName_picker = 'name';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    const elPicker = this.state.elPicker_visible ? (
      <div className="elPicker">
        <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
          {dataSource_picker.items.every(item => {
            return item[valueColumnName_picker] !== selection_picker;
          }) ? <Option value=''/> : null}
          {dataSource_picker.items.map(item => {
            const colValue = item[valueColumnName_picker];
            const labelColValue = item[labelColumnName_picker];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    const style_elTweetnacl = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen RegistroScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elRoundimage">
            <div style={style_elRoundimage} onClick={this.onClick_elRoundimage}  />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.register_text_490922.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elName">
            <Input className="baseFont" style={style_elName} type="text" placeholder={this.context.locStrings.register_field_920487} onChange={this.textInputChanged_elName} value={this.getValue_elName()}  />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.register_button_110106}
            </Button>
          </div>
          
          { elPicker }
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.login1_navbar_464384}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_registro} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
            <div className="elTweetnacl" style={style_elTweetnacl} />
          </div>
        </div>
      </div>
    )
  }
  
}
