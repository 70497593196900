// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_POC_Users extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['created_at'] = "2022-10-25 06:06:49.649068+00";
    item['id'] = "5398136b-19a7-445c-a025-08dfed0a24e8";
    item['properties'] = "";
    item['pub_sig_key'] = "f4771ffd6bd4f9be2debb5dd0022eafd8eb6ae9b9269d3b7410bac0f7ee88814";
    item['name'] = "Fazel";
    item['priv_sig_key'] = "25436bc0338668c439cc4adfe6d709aa802487b35e0fad4565c4d8c283d4bdb0f4771ffd6bd4f9be2debb5dd0022eafd8eb6ae9b9269d3b7410bac0f7ee88814";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "5af7f7d3948f8cc11cc0b615816c75a5bff0735716831abbc2a6759e95a5291851a791757fe02384c65bcfd627c5c37ebc5f3f59598b78ac17e208f0911f87c2";
    item['id'] = "fa774814-17b2-4252-91da-caeae99e84d2";
    item['properties'] = "";
    item['pub_sig_key'] = "51a791757fe02384c65bcfd627c5c37ebc5f3f59598b78ac17e208f0911f87c2";
    item['name'] = "Thomas";
    item['created_at'] = "2022-10-25 06:07:04.49053+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "a4f912cf60ded09516f8a0e3224ac454c6f095f101804d78a9b42eca52bf6796d424b62fa79ce06d3effb9c804c0ef721e13e315045fa20343373e9af5f3c4f9";
    item['id'] = "16ea481e-8776-4245-bf7d-697a6d770665";
    item['properties'] = "";
    item['pub_sig_key'] = "d424b62fa79ce06d3effb9c804c0ef721e13e315045fa20343373e9af5f3c4f9";
    item['name'] = "Amelie";
    item['created_at'] = "2022-10-25 06:07:15.388501+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "ec9cb511af4df5927d6ec976f877f547ce2e001f10e5ae52d3405c8a3311227b8b4889f547552592a4c6f5aa5e28e8db4e63704e2bc908048cfdc5a7bb00a6e4";
    item['id'] = "52bf998b-df20-4cc4-8241-8cd9e12481c3";
    item['properties'] = "";
    item['pub_sig_key'] = "8b4889f547552592a4c6f5aa5e28e8db4e63704e2bc908048cfdc5a7bb00a6e4";
    item['name'] = "Daniel";
    item['created_at'] = "2022-10-25 06:07:24.651219+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "5a00292791be52e973117488224934a645dbd0ab5f05b5e6cc38eaece8a4c98c812468186dacbac1c0fd766e85b9c14e9be1397390b83a97fd4874caa4c9554d";
    item['id'] = "5f636044-1e8e-41f2-8696-32cd326ff812";
    item['properties'] = "";
    item['pub_sig_key'] = "812468186dacbac1c0fd766e85b9c14e9be1397390b83a97fd4874caa4c9554d";
    item['name'] = "Eli";
    item['created_at'] = "2022-10-25 06:07:34.560799+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "7151436a343d171d743e3b64d0269e8b700978ab7fd2ca15c8ecfd75147f2f21646f27626c1a76d66a594b064878c8e57a93bb1d3fa50b69ece3f42f6ba19a97";
    item['id'] = "fd6f92b4-6f2d-4984-92ba-98e6cb55495d";
    item['properties'] = "";
    item['pub_sig_key'] = "646f27626c1a76d66a594b064878c8e57a93bb1d3fa50b69ece3f42f6ba19a97";
    item['name'] = "Mabel";
    item['created_at'] = "2022-10-25 06:07:48.977359+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "373e68963427a05a664214d431f18f537f3345bbef9cc5bd10499085163b70780ac8ef5eea2df470f10ba250e6fcad453f2614854638bf026d1356646abcd955";
    item['id'] = "baaf2f69-ffb1-4645-81e2-387506a669f4";
    item['properties'] = "";
    item['pub_sig_key'] = "0ac8ef5eea2df470f10ba250e6fcad453f2614854638bf026d1356646abcd955";
    item['name'] = "José Postman";
    item['created_at'] = "2022-10-31 01:52:24.641895+00";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['priv_sig_key'] = "37ab2081ec6fd3d2aba632a0bc56a0554859841ce2cdab7ad4ecaf8ca4f78d5918898bd742f8d4ea32d7a08991eb30bd1ddca10edcc092865fa859137a355f76";
    item['id'] = "079f6e44-6868-4b14-83af-00e260a80b1b";
    item['properties'] = "";
    item['pub_sig_key'] = "18898bd742f8d4ea32d7a08991eb30bd1ddca10edcc092865fa859137a355f76";
    item['name'] = "Pedro Postman";
    item['created_at'] = "2022-10-31 01:59:00.748768+00";
    item.key = key++;
  }

}
