import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elImage from './images/HiceUnFavorScreen_elImage_773268.png';
import moment from 'moment';
import {createClient} from '@supabase/supabase-js';
import nacl from 'tweetnacl';
import scrypt from 'scrypt-async';
import btn_icon_back_hiceunfavor from './images/btn_icon_back_hiceunfavor.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class HiceUnFavorScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {

    let contactsSheet = this.context.appActions.getDataSheet('contacts')
    if(contactsSheet.items.length == 1 && contactsSheet.items[0].human_id == ''){
    	contactsSheet.loadFromJson([])
    }
    let scannedHumanId = this.context.appActions.dataSlots['ds_db_qr_scan']||''
    
    if(scannedHumanId.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi)){
      //this.setState({received_by:  scannedHumanId});
      this.pickerValueChanged_elReceived_by({target:{value:scannedHumanId}})
    }
    
    let dropdown = this.context.appActions.getDataSheet('tempDropdownData')
    dropdown.loadFromJson([])
    
    const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
      , this.context.appActions.dataSlots['ds_supabase_api_key']
      , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
      //, options
    );
    //this.context.appActions.updateDataSlot('ds_display_data', {componentState:1});
	//this.context.appActions.updateDataSlot('ds_display_data', {{componentState:2},...this.context.appActions.dataSlots['ds_display_data']});
    
    //favor requests
    (async ()=>{
      let { data: favor_requests, error } = await supabase
        .from('_favor_requests')
        .select('*,from_human:_humans!_favor_requests_from_human_fkey(id,name)')
        .eq('to_human', this.context.appActions.dataSlots['ds_current_human_id'])
      	.eq('active', true)
	  //this.context.appActions.setState({loading: false});
      console.log('favor requests',favor_requests)
      //console.log('props', this.props)
      
      //console.log('dropdown',dropdown.items)
      //console.log('contacts',this.context.appActions.getDataSheet('contacts').items.length,this.context.appActions.getDataSheet('contacts').items)
      this.context.appActions.updateDataSlot('ds_display_data', {
      	favorRequests : favor_requests,
        displayContactsInterface : this.context.appActions.getDataSheet('contacts').items.length?true:false
      });
      this.populatePreSelectedRequest()
      //this.context.displayData =  {favor_requests, ...this.context.displayData};
      //this.runWhenAllDataLoaded()
    })();
  }

  componentWillUnmount() {
  }

  manageElementVisibilities(){
    const displayData = this.context.appActions.dataSlots['ds_display_data']
    if(displayData.displayContactsInterface){
      document.querySelector(".elReceived_by").style.display = "block"
    }else{
      document.querySelector(".elReceived_by").style.display = "none"
    }
    let dropdown = this.context.appActions.getDataSheet('tempDropdownData')
    
    if(!displayData.favorRequests) return;
    
    let selectedContactId = this.state.received_by ;
    let dropdownNewItems = []
    let thereIsARequestFromTheSelectedHumanId = false
    displayData.favorRequests.every((request)=>{
      console.log('question',request.from_human.id , selectedContactId,request.from_human.id == selectedContactId)
      if(request.from_human.id == selectedContactId){
        thereIsARequestFromTheSelectedHumanId = true
        const date = new Date(request.created_at).toLocaleString(this.context.appActions.dataSlots['ds_activeLang'], {year:'numeric', month:'numeric', day: 'numeric'})
        dropdownNewItems.push({label:`${request.skill} (${date})`,value:request.id})
      }
      return true;
    })

    if(thereIsARequestFromTheSelectedHumanId){
      dropdown.loadFromJson(dropdownNewItems)
      if(dropdownNewItems.length == 1){
      	//this.pickerValueChanged_elReceived_by({target:{value:displayData.favorRequests[0].id}})
      }
      document.querySelector(".elText4").style.display = "block"
      document.querySelector(".elFavor_request").style.display = "block"
    }else{
      document.querySelector(".elText4").style.display = "none"
      document.querySelector(".elFavor_request").style.display = "none"
    }



    //*/
  }
  calculateTotalHours (){
    if(!this.isFirstCall('calculateTotalHours')) 
      return;
    let hours = +this.state.horas||0
    let minutes = +(this.state.minutos/60)||0
    let skillName = this.state.skill||null
    let received_by = this.state.received_by
    let skillsInfo = this.getSkillInfo(skillName);
    let displayData = this.context.appActions.dataSlots['ds_display_data'];
    
    let hoursSubtotal = Number(hours) + Number(minutes);
    let educationSubtotal = 0;
    let experienceSubtotal = 0;
    if(typeof skillsInfo == "object"){
    	if(skillsInfo.skillEducationMonths){
          if(skillsInfo.skillEducationMonths > 120)
            skillsInfo.skillEducationMonths = 120
          educationSubtotal = Math.round(((skillsInfo.skillEducationMonths * hoursSubtotal)/120)*100)/100;
        }
      	if(skillsInfo.skillExperienceMonths){
          if(skillsInfo.skillExperienceMonths > 120)
            skillsInfo.skillExperienceMonths = 120
          experienceSubtotal = Math.round(((skillsInfo.skillExperienceMonths * hoursSubtotal)/120)*100)/100;
        }
    }else{
    	skillsInfo = {skillExperienceMonths:0,skillEducationsMonths:0}
    }
    let grandTotal = Number(hoursSubtotal) + Number(educationSubtotal) + Number(experienceSubtotal);
    grandTotal = Math.round(grandTotal * 100)/100;
    
    this.context.appActions.updateDataSlot('ds_display_data', {
      ...displayData,
      	skillEducationMonths: skillsInfo.skillEducationMonths,
      	skillExperienceMonths: skillsInfo.skillExperienceMonths,
        hoursSubtotal: Math.round(hoursSubtotal*100)/100,
        educationSubtotal: educationSubtotal,
        experienceSubtotal: experienceSubtotal,
        grandTotal: grandTotal
    });

    /*this.setState({
      hoursSubtotal: hoursSubtotal,
      educationSubtotal: educationSubtotal,
      experienceSubtotal: experienceSubtotal,
      grandTotal: grandTotal
    });*/
    
    
    
	console.log('base hours',hours);
    console.log('base minutes', minutes);
    console.log('skillName',skillName);
    console.log('skill info', this.getSkillInfo(skillName))
    console.log('hoursSubtotal',hoursSubtotal)
    console.log('educationSubtotal',educationSubtotal)
    console.log('experienceSubtotal',experienceSubtotal)
    console.log('grandTotal',grandTotal)
  }
  getContactById(id){
    let contactsSheet = this.context.appActions.getDataSheet('contacts')
    let selectedContact
    contactsSheet.items.every((contact)=>{
    	if(contact.human_id == id){
          	selectedContact = contact;
        	return false;
        }
      	return true;
    })
  	return selectedContact||null;
  }
  getSkillInfo(skillName){
    if(typeof skillName != "string")
      return false;
    let skills = this.context.appActions.getDataSheet('skills').items
    let selectedSkill = false
    try{
		skills.every((skill)=>{
        	if(skill.name == skillName){
              selectedSkill = skill;	
              return false
            }
          	return true;
        })
	}catch(e){console.log(e)}

    return selectedSkill;
    
  }
  isFirstCall(key){
    if(window.localStorage.getItem('isFirstCall_' + key)){
    	window.localStorage.removeItem('isFirstCall_' + key)
      	return false;
    }else{
    	window.localStorage.setItem('isFirstCall_' + key, 1)
      	return true
    }
  }
  populatePreSelectedRequest(){
  
    try{console.log('this.props.dataSheetId == "favorRequests"', this.props.dataSheetId == "favorRequests");
		if(this.props.dataSheetId == "favorRequests"){
          console.log("favor request data", this.props.dataSheetRow.data)
        	//prepopulate data if following a favor request link
          	//human id
          	this.pickerValueChanged_elReceived_by({target:{value:this.props.dataSheetRow.data.from_human.id}})
          	//request
          	this.pickerValueChanged_elFavor_request({target:{value:this.props.dataSheetRow.data.id}})
            //skill
          	this.pickerValueChanged_elSkill({target:{value:this.props.dataSheetRow.data.skill}})
          	//hours and minutes
          	const actualHours = this.props.dataSheetRow.data.actual_hours
          	if(actualHours % 1){
              console.log("_hours",actualHours - (actualHours%1));
              console.log("_minutes",(actualHours%1)*60)
            	this.pickerValueChanged_elHoras({target:{value:(actualHours - (actualHours%1))+''}})
             	this.pickerValueChanged_elMinutos({target:{value:((actualHours%1)*60)+''}})
            }else{
            	this.pickerValueChanged_elHoras({target:{value:actualHours}})
            }
        }
	}catch(e){console.log(e.message)}
  }

  componentDidUpdate(prevProps, prevState) {
    this.manageElementVisibilities();
    this.calculateTotalHours()
  }

  onClick_elImage = async () => {
    // Go to screen 'Obtener QR del Perfil'
    this.context.appActions.goToScreen('obtenerQRDelPerfil', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  getValue_elReceived_by = () => {
    return this.state.received_by !== undefined ? this.state.received_by : '';
  }
  
  pickerValueChanged_elReceived_by = (event) => {
    this.setState({received_by: event.target.value});
    
    // Write your script here
    
  }
  
  
  getValue_elFavor_request = () => {
    return this.state.favor_request !== undefined ? this.state.favor_request : '';
  }
  
  pickerValueChanged_elFavor_request = (event) => {
    this.setState({favor_request: event.target.value});
  }
  
  
  getValue_elSkill = () => {
    return this.state.skill !== undefined ? this.state.skill : '';
  }
  
  pickerValueChanged_elSkill = (event) => {
    this.setState({skill: event.target.value});
  }
  
  
  getValue_elHoras = () => {
    return this.state.horas !== undefined ? this.state.horas : '';
  }
  
  pickerValueChanged_elHoras = (event) => {
    this.setState({horas: event.target.value});
  }
  
  
  getValue_elMinutos = () => {
    return this.state.minutos !== undefined ? this.state.minutos : '';
  }
  
  pickerValueChanged_elMinutos = (event) => {
    this.setState({minutos: event.target.value});
  }
  
  onClick_elButton = async () => {
    // Write your script here
    console.log(this.state)
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if(!regexExp.test(this.state.received_by)){
    	return false;
    }
    let displayData = this.context.appActions.dataSlots['ds_display_data']
    
    var grandTotal = displayData.grandTotal || 0
    if(grandTotal <=0){
    	return false;
    }
    
    /*
    Example Schema:
    {
      "dateTime":"2022-10-26T15:00:00-04:00",
      "givenBy":"5f636044-1e8e-41f2-8696-32cd326ff812",
      "receivedBy":"5398136b-19a7-445c-a025-08dfed0a24e8",
      "agreedTotalHours":2.5,
      "latitude":37.6,
      "longitude":-95.665,
      "skills":["driving", "local touristic knowledge"],
      "skillExperienceMonths":0,
      "skillEducationMonths":0,
      "specializedMeterials":true,
      "actualHours":2.5,
      "nonce":"eb02cb23905bcf8d3c8ad308"
    }
    */
    var statement = {
    	"dateTime":moment().format(),
      	"givenBy":this.context.appActions.dataSlots['ds_current_human_id'],
      	"receivedBy":this.state.received_by,
      	"agreedTotalHours": grandTotal,
      	"nonce": Buffer.from(nacl.randomBytes(24)).toString("hex")
    }
    if(this.state.skill && this.state.skill.length){
    	statement.skills = [{
          "name":this.state.skill
        }]
    }
    if(+displayData.hoursSubtotal != +grandTotal){
    	statement.actualHours = displayData.hoursSubtotal   
    }
    try{
    	if (displayData.skillEducationsMonths||0 > 0)
          statement.skills[0].skillEducationMonths = displayData.skillEducationMonths
      	if (displayData.skillExperienceMonths||0 > 0)
          statement.skills[0].skillExperienceMonths = displayData.skillExperienceMonths
      
    }catch(e){}
    try{
    	if(this.state.favor_request && regexExp.test(this.state.favor_request)){
        	statement.favorRequest = this.state.favor_request
        }
    }catch(e){console.log(e)}
    console.log('statement', statement)
    //return;
    
    
    
    const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
      , this.context.appActions.dataSlots['ds_supabase_api_key']
      , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
      //, options
    );
    /*
    try{
      let { data, error } = await supabase
      .rpc('current_human')
    }catch(e){}
    */
    
    try{
      
      const { data, error } = await supabase
      .from('_unsigned_favors')
      .insert([
        { 
          given_by: statement.givenBy, 
          received_by: statement.receivedBy,
          statement: statement
        },
      ]).select()
      if(!error && data){
      	this.context.appActions.updateDataSlot('ds_qr_data_to_generate'
                                             , this.context.appActions.dataSlots['ds_server_name']
                                             +"/firmar/"+data[0].id);
    	this.context.appActions.goToScreen('presentarParaFirma', this.context.baseScreenId, { transitionId: 'fadeIn' });
      }
    }catch(e){
      console.log(e.message);
    }
    //console.log(this.context);
    //console.log(supabase);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_received_by = this.getValue_elReceived_by();
    // Source datasheet and selected data column for picker element 'received_by'
    const dataSource_received_by = this.context.appActions.getDataSheet('contacts');
    const valueColumnName_received_by = 'human_id';
    const labelColumnName_received_by = 'name';
    
    const style_elReceived_by = {
      pointerEvents: 'auto',
     };
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_favor_request = this.getValue_elFavor_request();
    // Source datasheet and selected data column for picker element 'favor_request'
    const dataSource_favor_request = this.context.appActions.getDataSheet('tempDropdownData');
    const valueColumnName_favor_request = 'value';
    const labelColumnName_favor_request = 'label';
    
    const style_elFavor_request = {
      pointerEvents: 'auto',
     };
    const style_elText5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_skill = this.getValue_elSkill();
    // Source datasheet and selected data column for picker element 'skill'
    const dataSource_skill = this.context.appActions.getDataSheet('skills');
    const valueColumnName_skill = 'name';
    const labelColumnName_skill = 'name';
    
    const style_elSkill = {
      pointerEvents: 'auto',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_horas = this.getValue_elHoras();
    const items_horas = [
      {"key": 1, "label": " - ", "value": ""},
      {"key": 2, "label": "1", "value": "1"},
      {"key": 3, "label": "2", "value": "2"},
      {"key": 4, "label": "3", "value": "3"},
      {"key": 5, "label": "4", "value": "4"},
      {"key": 6, "label": "5", "value": "5"},
      {"key": 7, "label": "6", "value": "6"},
      {"key": 8, "label": "7", "value": "7"},
      {"key": 9, "label": "8", "value": "8"},
    ];
    
    const style_elHoras = {
      pointerEvents: 'auto',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_minutos = this.getValue_elMinutos();
    const items_minutos = [
      {"key": 1, "label": " - ", "value": ""},
      {"key": 2, "label": "5", "value": "5"},
      {"key": 3, "label": "15", "value": "15"},
      {"key": 4, "label": "30", "value": "30"},
      {"key": 5, "label": "45", "value": "45"},
    ];
    
    const style_elMinutos = {
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText6 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_education_hours_added = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.educationSubtotal
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_education_hours_added = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_education_hours_added((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elEducation_hours_added = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText7 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_expiration_hours_added = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.experienceSubtotal
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_expiration_hours_added = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_expiration_hours_added((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elExpiration_hours_added = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText8 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_total_hours = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.grandTotal
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_total_hours = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_total_hours((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_elTotal_hours = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMomentJS = {
      pointerEvents: 'auto',
     };
    const style_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    const style_elTweetnacl = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen HiceUnFavorScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.hiceunfavor_text_551066}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elImage_773268">
          <div className="elImage">
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          </div>
          <div className="elReceived_by">
            <Select className="baseFont" style={style_elReceived_by}  onChange={this.pickerValueChanged_elReceived_by} value={selection_received_by} >
              {dataSource_received_by.items.every(item => {
                return item[valueColumnName_received_by] !== selection_received_by;
              }) ? <Option value=''/> : null}
              {dataSource_received_by.items.map(item => {
                const colValue = item[valueColumnName_received_by];
                const labelColValue = item[labelColumnName_received_by];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{this.context.locStrings.hiceunfavor_text4_207479}</div>
            </div>
          </div>
          
          <div className="elFavor_request">
            <Select className="baseFont" style={style_elFavor_request}  onChange={this.pickerValueChanged_elFavor_request} value={selection_favor_request} >
              {dataSource_favor_request.items.every(item => {
                return item[valueColumnName_favor_request] !== selection_favor_request;
              }) ? <Option value=''/> : null}
              {dataSource_favor_request.items.map(item => {
                const colValue = item[valueColumnName_favor_request];
                const labelColValue = item[labelColumnName_favor_request];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elText5">
            <div className="baseFont" style={style_elText5}>
              <div>{this.context.locStrings.hiceunfavor_text5_109651}</div>
            </div>
          </div>
          
          <div className="elSkill">
            <Select className="baseFont" style={style_elSkill}  onChange={this.pickerValueChanged_elSkill} value={selection_skill} >
              {dataSource_skill.items.every(item => {
                return item[valueColumnName_skill] !== selection_skill;
              }) ? <Option value=''/> : null}
              {dataSource_skill.items.map(item => {
                const colValue = item[valueColumnName_skill];
                const labelColValue = item[labelColumnName_skill];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.context.locStrings.hiceunfavor_text2_741758}</div>
            </div>
          </div>
          
          <div className="elHoras">
            <Select className="baseFont" style={style_elHoras}  onChange={this.pickerValueChanged_elHoras} value={selection_horas} >
              {items_horas.every(item => {
                return item.value !== selection_horas;
              }) ? <Option value=''/> : null}
              {items_horas.map(item => {
                return <Option key={item.key} value={item.value} label={item.label} />
              })}
            </Select>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elText3_868397">
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.hiceunfavor_text3_868397}</div>
            </div>
          </div>
          
          </div>
          <div className="elMinutos">
            <Select className="baseFont" style={style_elMinutos}  onChange={this.pickerValueChanged_elMinutos} value={selection_minutos} >
              {items_minutos.every(item => {
                return item.value !== selection_minutos;
              }) ? <Option value=''/> : null}
              {items_minutos.map(item => {
                return <Option key={item.key} value={item.value} label={item.label} />
              })}
            </Select>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elTextCopy_128296">
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{this.context.locStrings.hiceunfavor_textcopy_128296}</div>
            </div>
          </div>
          
          </div>
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elText6">
            <div className="baseFont" style={style_elText6}>
              <div>{this.context.locStrings.hiceunfavor_text6_162543}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elEducation_hours_added_749983">
          <div className="elEducation_hours_added">
            <div className="baseFont" style={style_elEducation_hours_added}>
              <div>{value_education_hours_added}</div>
            </div>
          </div>
          
          </div>
          <div className="elText7">
            <div className="baseFont" style={style_elText7}>
              <div>{this.context.locStrings.hiceunfavor_text7_556706}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elExpiration_hours_added_308310">
          <div className="elExpiration_hours_added">
            <div className="baseFont" style={style_elExpiration_hours_added}>
              <div>{value_expiration_hours_added}</div>
            </div>
          </div>
          
          </div>
          <div className="elText8">
            <div className="baseFont" style={style_elText8}>
              <div>{this.context.locStrings.hiceunfavor_text8_316254}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_HiceUnFavorScreen_elTotal_hours_301904">
          <div className="elTotal_hours">
            <div className="baseFont" style={style_elTotal_hours}>
              <div>{value_total_hours}</div>
            </div>
          </div>
          
          </div>
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.hiceunfavor_button_250430}
            </Button>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen4_navbar_1001093}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_hiceunfavor} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elMomentJS" style={style_elMomentJS} />
            <div className="elSupabaseJS" style={style_elSupabaseJS} />
            <div className="elTweetnacl" style={style_elTweetnacl} />
          </div>
        </div>
      </div>
    )
  }
  
}
