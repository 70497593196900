import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class FavorListItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elHotspot = async () => {
    // Go to screen 'Favor'
    const param_param0 = this.props.dataSheetRow ? this.props.dataSheetRow.id : '';
    this.context.appActions.goToScreen('favor'+'/'+param_param0, this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let transformPropValue_text5 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input){
        if(input == this.context.appActions.dataSlots['ds_current_human_id']){
          input = "Recibido"
        }else{
          input = "Dado"
        }
      }
      return input;
    }
    
    const value_text5 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text5(this.props.dataSheetRow ? this.props.dataSheetRow.received_by : ''));
    
    const style_elText5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if(input){
      	
        let date = new Date(input).toLocaleDateString(
        this.context.appActions.dataSlots['ds_activeLang'],
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
        );
        input = date
      }
      return input;
    }
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text2(this.props.dataSheetRow ? this.props.dataSheetRow.created_at : ''));
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.hours : '');
    
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="FavorListItem cardBg">
        <div className="layoutFlow">
          <div className="elText5">
            <div className="headlineFont" style={style_elText5}>
              <div>{value_text5}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.favorlistitem_text_974249}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.favorlistitem_text3_861081}</div>
            </div>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{value_text4}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
