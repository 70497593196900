import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Habilidad from './Habilidad';
import FormularioDeHabilidades from './FormularioDeHabilidades';
import btn_icon_104340 from './images/btn_icon_104340.png';
import btn_icon_back_mishabilidades from './images/btn_icon_back_mishabilidades.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class MisHabilidadesScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elFab = async () => {
    // Write your script here
    this.context.appActions.updateDataSlot('ds_displayAddForm', 1);
    
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_skills = this.context.dataSheets['skills'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('skills').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elFormulario = {
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
     };
    const elFormulario = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_displayAddForm'] : '')) ? (
      <div className="hasNestedComps elFormulario" style={style_elFormulario}>
        <FormularioDeHabilidades {...this.props} ref={(el)=> this._elFormulario = el} />
      </div>
     ) : null;
    
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen MisHabilidadesScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.mishabilidades_text_29878}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <Habilidad dataSheetId={'skills'} dataSheetRow={row} {...{ 'skillExperienceMonths': row['skillExperienceMonths'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen21_navbar_666117}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_mishabilidades} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elFormulario }
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_104340} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  
}
