import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class BalancesPositiveItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elHotspot = async () => {
    // Go to screen 'Favores por Usuario'
    this.context.appActions.goToScreen('favoresPorUsuario', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f4f4f4',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_hours = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      console.log('hours input',input);
      if(input && input.hours){
      	input = input.hours
        	input = Math.round((input + Number.EPSILON) * 100) / 100;
      }
      return input;
    }
    
    const value_hours = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_hours(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
    
    const style_elHours = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elTextBlock3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      /*/
      if(input.length){
        //keep count
        if(typeof ScreenContext._myCounter == "undefined") ScreenContext._myCounter=0;
        input = input[ScreenContext._myCounter]
      }
      //*/
      console.log('name input',input)
      if(input && input.to_human){
      	input = input.to_human.name
      }
      return input;
    }
    
    const value_name = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_name(this.props.dataSheetRow ? this.props.dataSheetRow.name : ''));
    
    const style_elName = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="BalancesPositiveItem">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_BalancesPositiveItem_elHotspot_578614">
          <div className="elHotspot">
            <div className="actionFont" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
          </div>
          
          </div>
          <div className="elHours">
            <div className="headlineFont" style={style_elHours}>
              <div>{value_hours}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_BalancesPositiveItem_elTextBlock3_372869">
          <div className="elTextBlock3">
            <div className="baseFont" style={style_elTextBlock3}>
              <div>{this.context.locStrings.listitem1_textblock3_372869}</div>
            </div>
          </div>
          
          <div className="elName">
            <div className="headlineFont" style={style_elName}>
              <div>{value_name}</div>
            </div>
          </div>
          
          </div>
        </div>
        
      </div>
    )
  }
  
}
