import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import BalancesPositiveItem from './BalancesPositiveItem';

export default class BalancesNegativeItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    
    return (
      <div className="BalancesNegativeItem">
        <div className="layoutFlow">
          <div className="hasNestedComps elNamesAndHours">
            <div>
              <BalancesPositiveItem {...this.props} ref={(el)=> this._elNamesAndHours = el} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
