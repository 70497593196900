import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import btn_icon_222580 from './images/btn_icon_222580.png';
import {createClient} from '@supabase/supabase-js';
import img_state0_elImage from './images/PerfilScreen_state0_elImage_424854.png';
import btn_icon_back_perfil from './images/btn_icon_back_perfil.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class PerfilScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
	this.setState({loading: true});

    let human_id = 0
    this.context.displayData = {}
    //console.log('this props',this.props)
    let humansComments = this.context.appActions.getDataSheet('humansComments')
    humansComments.loadFromJson([])
    
    if(this.context.appActions.dataSlots['ds_view_param_1']){
    	human_id = this.context.appActions.dataSlots['ds_view_param_1']
    }else if(this.props.dataSheetId && (this.props.dataSheetId == "balancesNegative" || this.props.dataSheetId == "balancesPositive" )){
        human_id = this.props.dataSheetRow.id     
    }else{
    	human_id = this.context.appActions.dataSlots['ds_current_human_id']
    }
    
    if(human_id == this.context.appActions.dataSlots['ds_current_human_id']){
      	document.querySelector(".state0_elRequestFavorButton").style.display = "none"
    }
    this.context.appActions.updateDataSlot('ds_qr_data_to_generate'
                                         , this.context.appActions.dataSlots['ds_server_name']
                                         +"/perfil/"+human_id);
    //this.context.appActions.setState({loading: true});
    const supabase = createClient(this.context.appActions.dataSlots['ds_supabase_api_url']
      , this.context.appActions.dataSlots['ds_supabase_api_key']
      , {global:{headers:{Authorization: "Bearer "+this.context.appActions.dataSlots['ds_jwt']}}}
      //, options
    );
    //user information
    (async ()=>{
      const { data: profile_human, error } = await supabase
      	.from('_humans')
        .select()
      	.eq('id', human_id)
      	.single()
      
      this.context.displayData =  {profile_human, ...this.context.displayData};
      this.runWhenAllDataLoaded()
	  //this.context.appActions.setState({loading: false});
      //console.log('profile human',profile_human)
    })();
    //favors owed by user
    (async ()=>{
      const { data: negative_balances, error } = await supabase
        .rpc('favors_owed_by', {
          human_id
        })
      if (error) console.error(error)
	  //console.log('owed by',negative_balances)
      this.context.displayData =  {negative_balances, ...this.context.displayData};
      this.runWhenAllDataLoaded()
    })();
    //favors owed to user
    (async ()=>{
      const { data: positive_balances, error } = await supabase
        .rpc('favors_owed_to', {
          human_id
        })
      if (error) console.error(error)
	  //else console.log('owed to',positive_balances)
      this.context.displayData =  {positive_balances, ...this.context.displayData};
      this.runWhenAllDataLoaded()
    })();
    //favor requests
    (async ()=>{
      let { data: favor_requests, error } = await supabase
        .from('_favor_requests')
        .select('*')
        .eq('to_human', human_id)
      	.eq('active', true)
	  //this.context.appActions.setState({loading: false});
      //console.log('favor requests',favor_requests)
      this.context.displayData =  {favor_requests, ...this.context.displayData};
      this.runWhenAllDataLoaded()
    })();
    
    //favors stats
    (async ()=>{
      const { data: favors_stats, error } = await supabase
        .rpc('favors_stats', {
          human_id
        })
      if (error) console.error(error)
	  //console.log('favors stats',favors_stats)
      this.context.displayData =  {favors_stats, ...this.context.displayData};
      this.runWhenAllDataLoaded()
    })();
    
    //comments
    (async ()=>{
      const { data: _humans_comments, error } = await supabase
      	.from('_humans_comments')
        .select()
      	.eq('to_human', human_id)
      humansComments.loadFromJson(_humans_comments)
	  //this.context.appActions.setState({loading: false});
      //console.log('profile human',profile_human)
    })();
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  runWhenAllDataLoaded(){
    let displayData = this.context.displayData
    if(typeof displayData == "object" && Object.keys(displayData).length < 5) return false;
    const owes = (human_id) => {
      let response = false
    	displayData.negative_balances.every((balance)=>{
          if(displayData.profile_human.id == balance.from_human && human_id == balance.to_human){
            response = true 
            return false;
          }
          return true;
        })
      return response;
    }
  	let count = 0
    let unansweredOwedRequests = 0
    let oldestUnansweredOwedRequest = new Date()
    if(Array.isArray(displayData.favor_requests) && displayData.favor_requests.length){
      displayData.favor_requests.forEach((request)=>{
        count++
        console.log('owes', request.from_human, owes(request.from_human))
        if(owes(request.from_human)){
          unansweredOwedRequests++
          const unansweredDate = new Date(request.created_at)
          if(unansweredDate < oldestUnansweredOwedRequest){
              oldestUnansweredOwedRequest = unansweredDate
          }
        }
      })
    }
    displayData.unansweredOwedRequests = unansweredOwedRequests
    displayData.oldestUnansweredOwedRequest = oldestUnansweredOwedRequest
    this.context.appActions.updateDataSlot('ds_display_data', displayData);
    this.setState({loading: false});
    console.log('display data', displayData)
    
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elRequestFavorButton = async () => {
    // Go to screen 'Solicitar un Favor'
    this.context.appActions.goToScreen('solicitarUnFavor', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elImage = async () => {
    // Go to screen 'Compartir Perfil'
    this.context.appActions.goToScreen('compartirPerfil', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    
    const style_state0_elFab222580 = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_state0_elFab222580_outer = {
      display: 'none',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	input = input.profile_human.name
      }catch(e){}
      return input;
    }
    
    const value_name = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_name((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elName = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elHabilidadesLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_habilidadesContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      //console.log('habilidades listado', input)
      let monthsOrYears = (date)=>{
      	if(date > 12){
            date = (date / 12) + " años"
          }else{
            date = date + " meses"
          }
        return date;
      }
      let response = ""
      try{
        	input.profile_human.properties.skills.forEach((skill)=>{
            let qualifications = []
            if(skill.skillEducationMonths) 
              qualifications.push(monthsOrYears(skill.skillEducationMonths)+" de educación")
            if(skill.skillExperienceMonths) 
              qualifications.push(monthsOrYears(skill.skillExperienceMonths)+" de experiencia")
            response = response + skill.name 
            if(qualifications.length) response = response + " <span style='font-size:80%'>(" + qualifications.join(", ") + ")</span>"
            response = response + "<br>"
          })
      }catch(e){console.log(e)}
      if(response == "")
        response = "Aún no ha agregado ninguna habilidad."
      return response;
    }
    
    const value_habilidadesContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_habilidadesContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elHabilidadesContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    let transformCustomCSSPropValue_requestsLabel = (input) => {
      // This function modifies the value for property 'customCSS'.
      // There is a variable named 'input' that provides the property value.
      // The return value must be a string using DOM notation.
      // Example:
      //   return '"fontWeight": "bold"';
      //
      try{
        if(!input.favor_requests.length){
          return '"display":"none"';
        }else{
          return ''
        }	
      }catch(e){console.log(e);}
      return '"display":"none"'
    }
    const style_state0_elRequestsLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      ...(() => {  // append customCSS property of 'requestsLabel'
        let val = transformCustomCSSPropValue_requestsLabel((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_requestsContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
        	input = "Cantidad de solicitudes: " + input.favor_requests.length
      }catch(e){console.log(e)}
      return input;
    }
    let transformCustomCSSPropValue_requestsContent = (input) => {
      // This function modifies the value for property 'customCSS'.
      // There is a variable named 'input' that provides the property value.
      // The return value must be a string using DOM notation.
      // Example:
      //   return '"fontWeight": "bold"';
      //
      try{
        if(!input.favor_requests.length){
            return '"display":"none"';
        }else{
            return ''
        }	
      }catch(e){console.log(e);}
      return '"display":"none"'
    }
    
    const value_requestsContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_requestsContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elRequestsContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      ...(() => {  // append customCSS property of 'requestsContent'
        let val = transformCustomCSSPropValue_requestsContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformCustomCSSPropValue_pendingFavorsLabel = (input) => {
      // This function modifies the value for property 'customCSS'.
      // There is a variable named 'input' that provides the property value.
      // The return value must be a string using DOM notation.
      // Example:
      //   return '"fontWeight": "bold"';
      //
      try{
        if(!input.unansweredOwedRequests){
            return '"display":"none"';
        }else{
            return ''
        }	
      }catch(e){console.log(e)}
      return '"display":"none"'
    }
    const style_state0_elPendingFavorsLabel = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      ...(() => {  // append customCSS property of 'pendingFavorsLabel'
        let val = transformCustomCSSPropValue_pendingFavorsLabel((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_pendingFavorsContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	let date = new Date(input.oldestUnansweredOwedRequest).toLocaleDateString(
            this.context.appActions.dataSlots['ds_activeLang'],
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          );
        
        const _es = (input.unansweredOwedRequests > 1)?"es":""
        const _n = (input.unansweredOwedRequests > 1)?"n":""
        const _s = (input.unansweredOwedRequests > 1)?"n":""
        		
        input = `Tiene ${input.unansweredOwedRequests} solicitud${_es} para devolver favor que 
      	está${_n} pendiente${_s} desde el ${date}` 
      }catch(e){console.log(e); input = ''}
      return input;
    }
    let transformCustomCSSPropValue_pendingFavorsContent = (input) => {
      // This function modifies the value for property 'customCSS'.
      // There is a variable named 'input' that provides the property value.
      // The return value must be a string using DOM notation.
      // Example:
      //   return '"fontWeight": "bold"';
      //
      try{
      	if(!input.unansweredOwedRequests){
              return '"display":"none"';
          }else{
              return ''
          }
      }catch(e){console.log(e)}
      return '"display":"none"'
    }
    
    const value_pendingFavorsContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_pendingFavorsContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elPendingFavorsContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      ...(() => {  // append customCSS property of 'pendingFavorsContent'
        let val = transformCustomCSSPropValue_pendingFavorsContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    const style_state0_elFavorsGivenLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_favorsGivenContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //Realizó X favores por un total de  Y horas
      try{
      	if(input.favors_stats.favors_given){
      	  	input = `Ha realizado ${input.favors_stats.favors_given} favores a ${input.favors_stats.favors_count_humans_given} personas
      			por un total de ${input.favors_stats.hours_given} horas (ajustadas a experiencia y estudios).`
          }else{
          	input = "Aún no ha realizado ningún favor."
          }
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_favorsGivenContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_favorsGivenContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elFavorsGivenContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_positiveBalanceContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //Tiene un balance a favor de X horas con Y personas
      try{
        let totalBalance = 0
        input.positive_balances.every((balance)=>{
          if(balance.from_human == input.profile_human.id){
        		totalBalance = totalBalance + balance.hours
            	return false
          }
          return true;
        })
        const _s = input.positive_balances.length?"s":""
        if(input.positive_balances.length){
          input = `Tiene un balance a favor de ${totalBalance} horas 
            con ${input.positive_balances.length} persona${_s}.`
        }else{
        	input = ''
        }
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_positiveBalanceContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_positiveBalanceContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elPositiveBalanceContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elFavorsReceivedLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_favorsReceivedContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
      	if(input.favors_stats.favors_received){
        		input = `Ha recibido ${input.favors_stats.favors_received} favores de ${input.favors_stats.favors_count_humans_received} personas
      		por un total de ${input.favors_stats.hours_received} horas (ajustadas a experiencia y estudios).`
          }else{
          	input = 'No ha recibido ningún favor aún.'
          }
      }catch(e){console.log(e)}
      
      return input;
    }
    
    const value_favorsReceivedContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_favorsReceivedContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elFavorsReceivedContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_negativeBalanceContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      try{
        let totalBalance = 0
        input.negative_balances.every((balance)=>{
          if(balance.from_human == input.profile_human.id){
        		totalBalance = totalBalance + balance.hours
            	return false
          }
          return true
        })
        const _s = input.negative_balances.length?"s":""
        totalBalance = totalBalance * -1
        if(input.negative_balances.length){
        	input = `Debe ${totalBalance} horas 
      	a ${input.negative_balances.length} persona${_s}.`
        }else{
        	input = '';
        }
      }catch(e){console.log(e)}
      return input;
    }
    
    const value_negativeBalanceContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_negativeBalanceContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elNegativeBalanceContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_personalizedFavorsOwedContent = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      // XXX te debe Y horas de favores
      let response = ''
      const owes = (human_id) => {
            let _owes = false
          	input.negative_balances.every((balance)=>{
                if(input.profile_human.id == balance.from_human && human_id == balance.to_human){
                  _owes = balance.hours * -1
                  return false;
                }
                return true;
              })
            return _owes;
          }
      try{
      	let balance = owes(this.context.appActions.dataSlots['ds_current_human_id'])
          const _s = (balance == 1)?"":"s"
        	const _es = (balance == 1)?"":"es"
        	if(balance){
          	response = `${input.profile_human.name} te debe ${balance} hora${_s} de favor${_es}`
          }
      }catch(e){console.log(e)}
      
      return response;
    }
    
    const value_personalizedFavorsOwedContent = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_personalizedFavorsOwedContent((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_display_data'] : '')));
    
    const style_state0_elPersonalizedFavorsOwedContent = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state0_elRequestFavorButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elCommentsLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elCommentsLabel_outer = {
      display: 'none',
     };
    
    const style_state0_elLeaveCommentButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_state0_elLeaveCommentButton_outer = {
      display: 'none',
     };
    
    const style_state0_elSendMessageButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_state0_elSendMessageButton_outer = {
      display: 'none',
     };
    const style_state0_elSupabaseJS = {
      pointerEvents: 'auto',
     };
    const style_state0_elImage = {
      backgroundImage: 'url('+img_state0_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen PerfilScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div className="appBg" style={style_state0_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elSpacer">
            <div />
          </div>
          
          <div className="flowRow flowRow_PerfilScreen_state0_elFab222580_222580">
          <div className="state0_elFab222580" style={style_state0_elFab222580_outer}>
            <Button className="actionFont" style={style_state0_elFab222580}  variant="fab" color="accent" >
              <img src={btn_icon_222580} alt="" style={{width: '51.064%', marginTop: '23.913%'}} />
            </Button>
          </div>
          
          </div>
          <div className="state0_elName">
            <div className="headlineFont" style={style_state0_elName}>
              <div>{value_name}</div>
            </div>
          </div>
          
          <div className="state0_elHabilidadesLabel">
            <div className="headlineFont" style={style_state0_elHabilidadesLabel}>
              <div>{this.context.locStrings.perfil_text4_521713}</div>
            </div>
          </div>
          
          <div className="state0_elHabilidadesContent">
            <div className="baseFont" style={style_state0_elHabilidadesContent}>
              <div><div dangerouslySetInnerHTML={{__html: value_habilidadesContent}}></div></div>
            </div>
          </div>
          
          <div className="state0_elRequestsLabel">
            <div className="headlineFont" style={style_state0_elRequestsLabel}>
              <div>{this.context.locStrings.perfil_text7_796980}</div>
            </div>
          </div>
          
          <div className="state0_elRequestsContent">
            <div className="baseFont" style={style_state0_elRequestsContent}>
              <div>{value_requestsContent}</div>
            </div>
          </div>
          
          <div className="state0_elPendingFavorsLabel">
            <div className="systemFontBold" style={style_state0_elPendingFavorsLabel}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.perfil_text9_635978.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="state0_elPendingFavorsContent">
            <div className="baseFont" style={style_state0_elPendingFavorsContent}>
              <div>{value_pendingFavorsContent}</div>
            </div>
          </div>
          
          <div className="state0_elFavorsGivenLabel">
            <div className="headlineFont" style={style_state0_elFavorsGivenLabel}>
              <div>{this.context.locStrings.perfil_text6_933844}</div>
            </div>
          </div>
          
          <div className="state0_elFavorsGivenContent">
            <div className="baseFont" style={style_state0_elFavorsGivenContent}>
              <div>{value_favorsGivenContent}</div>
            </div>
          </div>
          
          <div className="state0_elPositiveBalanceContent">
            <div className="baseFont" style={style_state0_elPositiveBalanceContent}>
              <div>{value_positiveBalanceContent}</div>
            </div>
          </div>
          
          <div className="state0_elFavorsReceivedLabel">
            <div className="headlineFont" style={style_state0_elFavorsReceivedLabel}>
              <div>{this.context.locStrings.perfil_text13_894201}</div>
            </div>
          </div>
          
          <div className="state0_elFavorsReceivedContent">
            <div className="baseFont" style={style_state0_elFavorsReceivedContent}>
              <div>{value_favorsReceivedContent}</div>
            </div>
          </div>
          
          <div className="state0_elNegativeBalanceContent">
            <div className="baseFont" style={style_state0_elNegativeBalanceContent}>
              <div>{value_negativeBalanceContent}</div>
            </div>
          </div>
          
          <div className="state0_elPersonalizedFavorsOwedContent">
            <div className="headlineFont" style={style_state0_elPersonalizedFavorsOwedContent}>
              <div>{value_personalizedFavorsOwedContent}</div>
            </div>
          </div>
          
          <div className="state0_elRequestFavorButton">
            <Button className="actionFont" style={style_state0_elRequestFavorButton}  color="accent" onClick={this.onClick_state0_elRequestFavorButton} >
              {this.context.locStrings.perfil_button_819617}
            </Button>
          </div>
          
          <div className="state0_elCommentsLabel" style={style_state0_elCommentsLabel_outer}>
            <div className="baseFont" style={style_state0_elCommentsLabel}>
              <div>{this.context.locStrings.perfil_text2_320418}</div>
            </div>
          </div>
          
          <div className="state0_elLeaveCommentButton" style={style_state0_elLeaveCommentButton_outer}>
            <Button className="actionFont" style={style_state0_elLeaveCommentButton}  color="accent" >
              {this.context.locStrings.perfil_buttoncopy2_586545}
            </Button>
          </div>
          
          <div className="state0_elSendMessageButton" style={style_state0_elSendMessageButton_outer}>
            <Button className="actionFont" style={style_state0_elSendMessageButton}  color="accent" >
              {this.context.locStrings.perfil_buttoncopy_164400}
            </Button>
          </div>
          
          <div className="state0_elSpacer2">
            <div />
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen11_navbar_208241}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_perfil} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elSupabaseJS" style={style_state0_elSupabaseJS} />
            <div className="state0_elImage" style={style_state0_elImage} onClick={this.onClick_state0_elImage}  />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    const style_state1_elFab222580 = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_state1_elFab222580_outer = {
      display: 'none',
     };
    
    return (
      <div className="AppScreen PerfilScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_PerfilScreen_state1_elFab222580_222580">
          <div className="state1_elFab222580" style={style_state1_elFab222580_outer}>
            <Button className="actionFont" style={style_state1_elFab222580}  variant="fab" color="accent" >
              <img src={btn_icon_222580} alt="" style={{width: '51.064%', marginTop: '23.913%'}} />
            </Button>
          </div>
          
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen11_navbar_208241}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_perfil} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
