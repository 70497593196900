// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "register_text_490922";
    item['es'] = "xFavor es tu plataforma donde puedes intercambiar favores con otros.\n\nPara empezar, ingresa tu nombre y apellido:";
    
    item = {};
    this.items.push(item);
    item['key'] = "register_field_920487";
    item['es'] = "Nombre Completo";
    
    item = {};
    this.items.push(item);
    item['key'] = "register_button_110106";
    item['es'] = "Empezar";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_navbar_823812";
    item['en'] = "Start";
    item['es'] = "Inicio";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen3_navbar_601813";
    item['en'] = "Do Favor";
    item['es'] = "Hacer un Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen4_navbar_351329";
    item['en'] = "Receive Favor";
    item['es'] = "Recibir un Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen4_navbar_1001093";
    item['en'] = "Do Favor";
    item['es'] = "Hice Un Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_745732";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text2_77945";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text3_719338";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_667146";
    item['es'] = "Horas a mi favor:";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text2_108259";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "horasamifavor2_text2_169241";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "horasamifavor2_text_413276";
    item['es'] = "Horas por Reponer:";
    
    item = {};
    this.items.push(item);
    item['key'] = "horasamifavor2_text2_671960";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "horasamifavor2_text_1047665";
    item['es'] = "Favores que he recibido:";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_1044332";
    item['es'] = "Horas de favores";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text2_660376";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text3_73349";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy_797782";
    item['es'] = "Personas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text4_578715";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp3_text_432392";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp3_text2_870425";
    item['es'] = "Horas que debo buscar corresponder";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text5_1016923";
    item['es'] = ">";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text_551066";
    item['es'] = "¿Quién Recibió el favor?";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text2_741758";
    item['es'] = "Cantidad de Horas / Minutos";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text3_868397";
    item['es'] = "h";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy_128296";
    item['es'] = "min";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_button_250430";
    item['es'] = "Preparar para Firma";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen7_navbar_944380";
    item['es'] = "Presentar para Firma";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_navbar_945923";
    item['es'] = "Firmar";
    
    item = {};
    this.items.push(item);
    item['key'] = "firmar_text_991236";
    item['es'] = "Text";
    
    item = {};
    this.items.push(item);
    item['key'] = "firmar_text2_578542";
    item['es'] = "Total De Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp2_text_928934";
    item['es'] = "Text";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp2_text2_458213";
    item['es'] = "Total De Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text_445469";
    item['es'] = "Text";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text2_290969";
    item['es'] = "Total De Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text3_536217";
    item['es'] = "recibí de";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text4_534043";
    item['es'] = "Yo,";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_dynamichourscopy_501780";
    item['es'] = "Text";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_dynamic_bycopy_222312";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_dynamic_givencopy_56305";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_textcopy_75014";
    item['es'] = "Yo,";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text_831992";
    item['es'] = "un favor de";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text2_81608";
    item['es'] = "horas de duración.";
    
    item = {};
    this.items.push(item);
    item['key'] = "firmar_button_1021444";
    item['es'] = "Firmar";
    
    item = {};
    this.items.push(item);
    item['key'] = "firmar_button2_264888";
    item['es'] = "CANCELAR";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_navbar_464384";
    item['es'] = "Registro";
    
    item = {};
    this.items.push(item);
    item['key'] = "recibirunfavor_textblock_800328";
    item['es'] = "Capture el código QR de un Favor o de una persona";
    
    item = {};
    this.items.push(item);
    item['key'] = "recibirunfavor_textblock2_499837";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen11_navbar_208241";
    item['es'] = "Perfil";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_textblock_745193";
    item['es'] = "Me comprometo a hacer mi mejor esfuerzo por devolverlo con el mismo espíritu en el que me fue dado.";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen12_navbar_755332";
    item['es'] = "Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock_754146";
    item['es'] = "El día";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock2_888270";
    item['es'] = "_";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock3_1002445";
    item['es'] = "giver";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock4_236867";
    item['es'] = "realizó un favor para";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock5_181397";
    item['es'] = "receiver";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock6_943593";
    item['es'] = "con una duración de";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock7_253415";
    item['es'] = "hours";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_textblock8_722633";
    item['es'] = "horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock8_440353";
    item['es'] = "horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock7_883198";
    item['es'] = "hours";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock6_373648";
    item['es'] = "con una duración de";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock5_233870";
    item['es'] = "receiver";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock4_91922";
    item['es'] = "realizó un favor para";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock3_699117";
    item['es'] = "giver";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock2_671792";
    item['es'] = "_";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_textblock_295969";
    item['es'] = "El día";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen14_navbar_11799";
    item['es'] = "A Quienes Debo";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen15_navbar_272289";
    item['es'] = "Quienes Me Deben";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_688207";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "balancerealizados_textblock_358821";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "balancerealizados_textblock2_546856";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "balancerealizados_textblock3_418576";
    item['es'] = "Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "balancerealizados_textblock4_140554";
    item['es'] = "Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textblock4_794261";
    item['es'] = "Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textblock3_372869";
    item['es'] = "Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textblock_670121";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "namesandhours_button_571909";
    item['es'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen17_navbar_824016";
    item['es'] = "Favores por Usuario";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresporusuario_text_705007";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresporusuario_textblock_937217";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_textblock_263641";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_textblock_438457";
    item['es'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_text_974249";
    item['es'] = "Fecha";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_text2_50037";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_text3_861081";
    item['es'] = "Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_text4_956607";
    item['es'] = "(Horas)";
    
    item = {};
    this.items.push(item);
    item['key'] = "favorlistitem_text5_179946";
    item['es'] = "Dado/Recibido";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_text_974197";
    item['es'] = "Habilidades";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_text2_378880";
    item['es'] = "que tengo para ofrecer";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_textblock_31216";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "habilidadesalosquetengoacceso_text_296157";
    item['es'] = "Habilidades Disponibles";
    
    item = {};
    this.items.push(item);
    item['key'] = "habilidadesalosquetengoacceso_text2_121995";
    item['es'] = "de quienes me deben favores";
    
    item = {};
    this.items.push(item);
    item['key'] = "habilidadesalosquetengoacceso_text3_676513";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen21_navbar_666117";
    item['es'] = "Mis Habilidades";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen22_navbar_141258";
    item['es'] = "Habilidades Disponibles";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_field_25696";
    item['es'] = "Habilidad";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_text_66093";
    item['es'] = "Agrega una Habilidad";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_field2_242665";
    item['es'] = "Años de Estudio";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_fieldcopy_928113";
    item['es'] = "Años de Experiencia";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_button_865759";
    item['es'] = "Agregar";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_field_679395";
    item['es'] = "Habilidad";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_field2_982235";
    item['es'] = "de Estudio";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy_476783";
    item['es'] = "de Experiencia";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_button_104053";
    item['es'] = "Agregar";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text_383720";
    item['es'] = "name";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text2_481281";
    item['es'] = "education";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textcopy_586598";
    item['es'] = "experience";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_iconbutton_88043";
    item['es'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresporusuario_text_571539";
    item['es'] = "Histórico de Favores";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text_137686";
    item['es'] = "Nombre";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_button_819617";
    item['es'] = "Solicitar Un Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_buttoncopy_164400";
    item['es'] = "Enviar Un Mensaje";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_buttoncopy2_586545";
    item['es'] = "Dejar un Comentario Público";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text2_546671";
    item['es'] = "A cuántos le debe y cuánto";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text3_670521";
    item['es'] = "Cuántos le deben y cuánto (si le debe a la persona que ve el perfil, puede ver el listado)";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text4_521713";
    item['es'] = "Habilidades";
    
    item = {};
    this.items.push(item);
    item['key'] = "favor_button_922590";
    item['es'] = "Dejar un comentario";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresrealizados_text5_193989";
    item['es'] = "Me deben";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresrecibidos_text3_243140";
    item['es'] = "Le debo";
    
    item = {};
    this.items.push(item);
    item['key'] = "habilidadesalosquetengoacceso_text4_641121";
    item['es'] = "habilidades";
    
    item = {};
    this.items.push(item);
    item['key'] = "favoresrealizados_text4_101120";
    item['es'] = "que pueden ser devueltos a mi por";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text_300950";
    item['es'] = "Nombre";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text2_596510";
    item['es'] = "Horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text3_403331";
    item['es'] = "Horas que me debe:";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text4_625539";
    item['es'] = "Habilidad";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_button_134987";
    item['es'] = "Solicitar Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen27_navbar_73020";
    item['es'] = "Solicitar un Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text_860991";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text_117898";
    item['es'] = "Solicitar un favor de";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text2_836107";
    item['es'] = "Nombre";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text3_60153";
    item['es'] = "Habilidad:";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text4_206097";
    item['es'] = "Cantidad de Horas:";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_text5_962540";
    item['es'] = "Mensaje:";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitarunfavor_button_400823";
    item['es'] = "Solicitar";
    
    item = {};
    this.items.push(item);
    item['key'] = "mishabilidades_text_29878";
    item['es'] = "Agrega aquí las habilidades con los que estás disponible para devolver un favor.";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text5_660408";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text6_628955";
    item['es'] = "Número de Solicitudes de Favores Pendientes. Si hay favores que debe en estas solicitudes, mostrar hace cuánto fueron solicitados.";
    
    item = {};
    this.items.push(item);
    item['key'] = "inicio_text2_611324";
    item['es'] = "Si hay solicitudes de favores pendientes, mostrar. Agregar un ícono urgente si se le deben horas al solicitante";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen28_navbar_477720";
    item['es'] = "Solicitudes Pendientes";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitudespendientes_text_933467";
    item['es'] = "Listado de Solicitudes de Favores Pendientes";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text4_207479";
    item['es'] = "El favor responde a una solicitud? Si es así, indique cuál.";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text7_796980";
    item['es'] = "Solicitudes de Favores Pendientes";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text8_295514";
    item['es'] = "Número de Solicitudes";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text9_635978";
    item['es'] = "Favores sin saldar\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text10_176853";
    item['es'] = "Número y tiempo sin saldar";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text6_933844";
    item['es'] = "Favores Realizados";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text11_544058";
    item['es'] = "Realizó X favores por un total de  Y horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text12_359281";
    item['es'] = "Cant Fav Real";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text13_457129";
    item['es'] = "favores a";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text12_1031500";
    item['es'] = "Tiene un balance a favor de X horas con Y personas";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text13_894201";
    item['es'] = "Favores Recibidos";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text14_720111";
    item['es'] = "Ha recibido X favores por un total de Y horas";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text15_608924";
    item['es'] = "Debe X horas a Y personas";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text16_257369";
    item['es'] = "XXX te debe Y horas de favores";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_text2_320418";
    item['es'] = "Comentarios";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen31_navbar_225993";
    item['es'] = "Compartir Perfil";
    
    item = {};
    this.items.push(item);
    item['key'] = "compartirperfil_text_740770";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "compartirperfil_text_537742";
    item['es'] = "Nombre";
    
    item = {};
    this.items.push(item);
    item['key'] = "compartirperfil_text2_215215";
    item['es'] = "Presenta este QR para compartir el perfil.";
    
    item = {};
    this.items.push(item);
    item['key'] = "perfil_iconbutton_511689";
    item['es'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text5_970998";
    item['es'] = "Pide a la persona a quién le realizaste el favor que entre a su perfil y te presente el código QR de su perfil, y apunta tu cámara para que lo registre.";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen32_navbar_657830";
    item['es'] = "Obtener QR del Perfil";
    
    item = {};
    this.items.push(item);
    item['key'] = "obtenerqrdelperfil_text_421287";
    item['es'] = "Pide a la persona a quien hayas realizado el favor, que entren a su perfil y te muestren el código QR, y luego obten el código apuntando la cámara.";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text5_109651";
    item['es'] = "Qué habilidad utilizaste?";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy2_67883";
    item['es'] = "¿Quién Recibió el favor?";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy3_806120";
    item['es'] = "El favor responde a una solicitud? Si es así, indique cuál.";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy4_797067";
    item['es'] = "Cantidad de Horas / Minutos";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy5_197050";
    item['es'] = "h";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy6_577296";
    item['es'] = "min";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_textcopy7_22278";
    item['es'] = "Qué habilidad utilizó?";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_buttoncopy_597744";
    item['es'] = "Preparar para Firma";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text6_162543";
    item['es'] = "Adicional por Estudios:";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text7_556706";
    item['es'] = "Adicional por Experiencia:";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text8_316254";
    item['es'] = "Total de Horas:";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_text9_749983";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_education_hours_addedcopy_308310";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "hiceunfavor_expiration_hours_addedcopy_301904";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp4_text5_497492";
    item['es'] = "(Ver más detalles…)";
    
    item = {};
    this.items.push(item);
    item['key'] = "twocolumntableitem_text_755566";
    item['es'] = "Label";
    
    item = {};
    this.items.push(item);
    item['key'] = "twocolumntableitem_text2_838820";
    item['es'] = "Value";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_text2_287121";
    item['es'] = "Tiene solicitudes de favores";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_iconbutton_810364";
    item['es'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_text_323354";
    item['es'] = "Número de solicitudes:";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_text3_39290";
    item['es'] = "Tiene solicitudes de personas a quien le debe horas de favor. Intente reponerlos lo más pronto posible.";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_text3_1043176";
    item['es'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp5_iconbutton_879123";
    item['es'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitudespendientes_text_106842";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "solicitudespendientes_text_216294";
    item['es'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem2_text_392581";
    item['es'] = "From";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_text2_545101";
    item['es'] = "Skill";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_text3_868259";
    item['es'] = "Message";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_text4_568261";
    item['es'] = "Hours";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_hourscopy_378151";
    item['es'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_button_256804";
    item['es'] = "⚠ Devolver Favor";
    
    item = {};
    this.items.push(item);
    item['key'] = "requestslist_respondbuttoncopy_1036845";
    item['es'] = "Realizar Favor";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
