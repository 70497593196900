import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import btn_icon_879123 from './images/btn_icon_879123.png';
import btn_icon_810364 from './images/btn_icon_810364.png';

export default class Comp5 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      elWarning_visible: false,
      elWarningtext_visible: false,
    };
  }

  componentDidMount() {
    let favorRequests =  this.context.appActions.getDataSheet('favorRequests').items
    let balancesNegative = this.context.appActions.getDataSheet('balancesNegative').items
    if(balancesNegative[0].id=="") return;
    let hasFavorsForNegativeBalances = false
    favorRequests.every((request)=>{
    	balancesNegative.every((balance)=>{console.log('baaaalance',balance)
        	if(balance.id == request.data.from_human.id){
            	hasFavorsForNegativeBalances = true;
              	return false; //exit loop
            }
            return true;
        })
      	if(hasFavorsForNegativeBalances){
        	return false;//exit loop
        }
        return true;
    })
    console.log(hasFavorsForNegativeBalances)
    if(hasFavorsForNegativeBalances){
    	this.setState({elWarning_visible: true});
      	this.setState({elWarningtext_visible: true});
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elIconButton = async () => {
    this.setState({elWarningtext_visible: !this.state.elWarningtext_visible});
  
    this.setState({elWarning_visible: !this.state.elWarning_visible});
  
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f4f4f4',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_879123+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elWarning = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_810364+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.760%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const elWarning = this.state.elWarning_visible ? (
      <div className="elWarning">
        <button className="actionFont" style={style_elWarning} />
      </div>
      
     ) : null;
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_text3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      let favorRequests = this.context.appActions.getDataSheet('favorRequests')
      //console.log('comp5 favor requests', favorRequests)
      return favorRequests.items.length;
    }
    
    const value_text3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text3((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_current_human_id'] : '')));
    
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elWarningtext = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elWarningtext = this.state.elWarningtext_visible ? (
      <div className="elWarningtext">
        <div className="baseFont" style={style_elWarningtext}>
          <div>{this.context.locStrings.comp5_text3_39290}</div>
        </div>
      </div>
      
     ) : null;
    
    return (
      <div className="Comp5">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
          
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
        </div>
        
        <div className="layoutFlow">
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{this.context.locStrings.comp5_text2_287121}</div>
            </div>
          </div>
          
          { elWarning }
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.context.locStrings.comp5_text_323354}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="headlineFont" style={style_elText3}>
              <div>{value_text3}</div>
            </div>
          </div>
          
          { elWarningtext }
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
