import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import QRCode from 'react-qr-code';
import btn_icon_back_presentarparafirma from './images/btn_icon_back_presentarparafirma.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class PresentarParaFirmaScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
     };
    const style_elQrwriter = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen PresentarParaFirmaScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
        </div>
        <div className="navBarContainer">
         <Appbar className="navBar">
          <div className="title">{this.context.locStrings.screen7_navbar_944380}</div>  <div className="backBtn" onClick={ (ev)=>{ this.context.appActions.goBack() } }><img src={btn_icon_back_presentarparafirma} alt="" style={{width: '50%'}} /> </div>
         </Appbar>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elQrwriter" style={style_elQrwriter}>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: "70%", width: "70%", padding: "15px" }}>
                <QRCode value={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_qr_data_to_generate'] : '')}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                size={600}
                viewBox={[0,0,600,600]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
